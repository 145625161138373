import { routing } from './routing';
import auth from './auth.store';
import user from './user.store';
import types from './types.store';
import settings from './settings.store';
import reports from './reports.store';
import view from './view.store';
import notifications from './notifications.store';

const store = {
  routing,
  auth,
  user,
  types,
  settings,
  reports,
  view,
  notifications,
};

export { store };
