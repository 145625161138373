import React, { useState } from 'react';
import { Modal, Form, Input } from 'antd';
import api from 'api';

import { NURSE_APPLICATIONS } from 'routes';
import { history } from 'stores/routing';
import { Button, Notification } from 'components';

export const RejectApplicationModalComp = ({ form, pk, name }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { getFieldDecorator } = form;

  const handleSubmit = () => {
    form.validateFields(async (validationErr, { reason }) => {
      if (validationErr) {
        return;
      }

      setLoading(true);

      try {
        await api.platformAdmin.nurseApplications.reject(pk, { reason });
        setOpen(false);
        Notification({
          message: 'Success',
          description: 'Nurse application was rejected.',
        });
        history.push(NURSE_APPLICATIONS);
      } catch (err) {
        setLoading(false);
        Notification({
          type: 'error',
          message: 'Error',
          description: `Can't reject nurse applications. Error message: ${err.message}.`,
        });
      }
    });
  };

  const title = `Reject application ${name ? `of ${name}` : ''}`;

  return (
    <>
      <Button type="danger" onClick={() => setOpen(true)}>
        Reject
      </Button>
      <Modal
        title={title}
        visible={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        footer={[
          <Button key="Cancel" onClick={() => setOpen(false)}>
            Cancel
          </Button>,
          <Button
            key="Reject"
            loading={loading}
            type="primary"
            onClick={handleSubmit}
            style={{ padding: '0 12px' }}
          >
            Reject application
          </Button>,
        ]}
      >
        <Form onSubmit={handleSubmit}>
          <Form.Item label="Reason">
            {getFieldDecorator('reason', {
              initialValue: '',
              rules: [
                { max: 255, message: 'The reason shouldn’t contain more than 255 symbols' },
                { required: true, message: 'Please fill a reason' }
              ],
            })(<Input.TextArea rows={4} />)}
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export const RejectApplicationModal =  Form.create({})(RejectApplicationModalComp);
