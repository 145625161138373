import { throttle } from 'lodash';
import jwt from 'jsonwebtoken';
import moment from 'moment';
import { history } from 'stores/routing';
import { SIGN_IN } from 'routes';

function RefreshTokenException({ success, status }) {
  this.success = success;
  this.status = status;
}

export const refreshToken = async refresh => {
  if (!localStorage.getItem('expires') || !refresh) {
    throw new RefreshTokenException({
      success: false,
      status: 401,
    });
  }

  const refreshTokenURL = `${process.env.REACT_APP_API_ENDPOINT}/auth/refresh/`;

  try {
    const response = await fetch(refreshTokenURL, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ refresh }),
    });
    return { success: true, data: await response.json() };
  } catch (error) {
    throw new RefreshTokenException({
      success: false,
      status: 401,
    });
  }
};

const refreshTokenTrottled = throttle(refreshToken, 5000, { leading: true });

const renewSession = async ({ withoutRedirect } = {}) => {
  const expires = localStorage.getItem('expires');

  if (!moment(expires * 1000).isBefore(new Date())) {
    return null;
  }

  try {
    const refresh = localStorage.getItem('token');
    const result = await refreshTokenTrottled(refresh);

    const { access } = result.data;
    const decoded = jwt.decode(access);

    if (!decoded) {
      throw new RefreshTokenException({
        success: false,
        status: 401,
      });
    }
    const { exp } = decoded;

    localStorage.setItem('accessToken', access);
    localStorage.setItem('expires', exp);

    return result;
  } catch (err) {
    console.log(err);

    localStorage.removeItem('accessToken');
    localStorage.removeItem('expires');
    localStorage.removeItem('loggedIn');

    refreshTokenTrottled.cancel();

    if (!withoutRedirect) {
      history.push(SIGN_IN);
    }

    return null;
  }
};

export default renewSession;
