import { observable, action, computed } from 'mobx';
import api from 'api';
import { Notification } from 'components';

class SettingsStore {
  @observable error = null;
  @observable loading = false;
  @observable organization = {};
  @observable documents = null;

  @computed
  get address() {
    return this.organization && this.organization.address;
  }

  getOrganization = async () => {
    this.setLoading();
    const result = await api.facility.organization.get();
    if (result.success) {
      this.setOrganization(result.data);
    }

    this.setLoading(false);
  };

  getDocuments = async () => {
    this.setLoading();
    const result = await api.facility.orientationDocuments.get();
    if (result.success) {
      this.setDocuments(result.data);
    }

    this.setLoading(false);
  };

  @action
  setDocuments = documents => {
    this.documents = documents;
  };

  @action
  setLoading = (loading = true) => {
    this.loading = loading;
  };

  @action
  setOrganization = organization => {
    this.organization = organization;
  };

  updateOrganization = async (values, onSuccess) => {
    this.setLoading();
    try {
      const response = await api.facility.organization.put(values);
      onSuccess();
      this.setOrganization(response.data);
    } catch (err) {
      if (!err.data || (err.data && !err.data.address)) {
        Notification({
          type: 'error',
          message: 'Error occurred',
          description: `Can't update facility information. Error: ${err.message}`,
        });
      }
      throw err;
    } finally {
      this.setLoading(false);
    }
  };
}

export default new SettingsStore();
