import {
  observable, action, reaction, computed,
} from 'mobx';
import jwt from 'jsonwebtoken';

import { MAIN, SIGN_IN, USER } from 'routes';
import { history } from 'stores/routing';
import api from 'api';
import renewSession from 'api/renewSession';
import { Notification } from 'components';

import userStore from './user.store';

const saveLocalStorage = (key) => (value) => {
  if (value) {
    localStorage.setItem(key, value);
  } else {
    localStorage.removeItem(key);
  }
};

class AuthStore {
  @observable error = null
  @observable successMessage = null
  @observable loading = true
  @observable token = localStorage.getItem('token')
  @observable user = localStorage.getItem('user')
  @observable accessToken = localStorage.getItem('accessToken')
  @observable convertedToken = localStorage.getItem('convertedToken')
  @observable expires = localStorage.getItem('expires')
  @observable loggedIn = localStorage.getItem('loggedIn')
  @observable isAdmin = localStorage.getItem('isAdmin')
  @observable facilityName = localStorage.getItem('facilityName')

  constructor() {
    reaction(() => this.token, saveLocalStorage('token'));
    reaction(() => this.user, saveLocalStorage('user'));
    reaction(() => this.accessToken, saveLocalStorage('accessToken'));
    reaction(() => this.convertedToken, saveLocalStorage('convertedToken'));
    reaction(() => this.expires, saveLocalStorage('expires'));
    reaction(() => this.loggedIn, saveLocalStorage('loggedIn'));
    reaction(() => this.isAdmin, saveLocalStorage('isAdmin'));
    reaction(() => this.facilityName, saveLocalStorage('facilityName'));

    if (this.authenticated) {
      this.fetchUserProfile();
    } else if (!this.authenticated && this.loggedIn) {
      this.renewSession();
    } else if (!this.loggedIn) {
      this.setLoading(false);
    }
  }

  @action resetForm = () => {
    this.error = null;
    this.successMessage = null;
    this.loading = false;
  }

  @action
  fetchUserProfile = async () => {
    try {
      const result = this.isAdmin
        ? await api.platformAdmin.profile.get()
        : await api.facility.profile.get();

      userStore.setProfile(result.data);
    } catch (err) {
      console.log(err);
    } finally {
      this.setLoading(false);
    }
  }

  renewSession = async () => {
    try {
      const result = await renewSession({ withoutRedirect: true });
      const { access } = result.data;
      const { exp } = jwt.decode(access);
      await this.setSession(this.token, access, exp);
      return true;
    } catch (err) {
      this.logout();
      return false;
    }
  }

  @action login = async (email, password, redirectUrl) => {
    this.setSuccessMessage(null);

    try {
      const result = await api.auth.signIn({ email, password });
      const { access, refresh, role } = result.data;
      const { exp } = jwt.decode(access);
      const isAdmin = role === 1;

      await this.setSession(refresh, access, exp, isAdmin);
      this.fetchUserProfile();

      history.push(redirectUrl || MAIN);
    } catch (err) {
      this.setError(err.message);
    }
  }

  @action loginAsFacility = async (id, facilityId) => {
    try {
      localStorage.setItem('facilityId', facilityId);
      const result = await api.platformAdmin.facilities.loginAsFacility(id);
      const { access, refresh, role, facility } = result.data;
      const { exp } = jwt.decode(access);
      const isAdmin = role === 1;

      this.logout();
      await this.setSession(refresh, access, exp, isAdmin, facility);
      this.fetchUserProfile();

      history.push(MAIN);
    } catch (err) {
      Notification({
        type: 'error',
        message: 'Error occurred',
        description: `Error while login as facility. Server error: ${err.message}`,
      });
    }
  }

  @action logoutFromFacility = async () => {
    try {
      const facilityId = localStorage.getItem('facilityId');
      const result = await api.platformAdmin.facilities.logoutFromFacility();
      const { access, refresh, role } = result.data;
      const { exp } = jwt.decode(access);
      const isAdmin = role === 1;


      this.logout();
      await this.setSession(refresh, access, exp, isAdmin);
      this.fetchUserProfile();

      history.push(`${USER.replace(':id', facilityId)}?isFacility=true`);
    } catch (err) {
      Notification({
        type: 'error',
        message: 'Error occurred',
        description: `Error while logout. Server error: ${err.message}`,
      });
    };
  }

  @action setSession = async (refresh, accessToken, expires, isAdmin, facilityName) => {
    this.loggedIn = true;
    this.token = refresh;
    this.accessToken = accessToken;
    this.expires = expires;
    this.loading = false;
    this.facilityName = facilityName;

    if (isAdmin) {
      this.isAdmin = isAdmin;
    }

    return true;
  }

  @action logout({ withoutRedirect } = {}) {
    this.token = null;
    this.accessToken = null;
    this.expires = 0;
    this.loggedIn = false;
    this.loading = false;
    this.isAdmin = null;
    this.facilityName = null;
    userStore.setProfile(null);
    if (!withoutRedirect) {
      window.location = SIGN_IN;
    }
  }

  async resetPassword(payload) { // eslint-disable-line
    try {
      await api.auth.resetPassword(payload);
    } catch (err) {
      Notification({
        type: 'error',
        message: 'Error occurred',
        description: `Can't reset password`,
      });
    }
  }

  @computed get authorized() {
    return !!this.token;
  }

  @computed
  get authenticated() {
    const now = new Date().getTime() + (1000 * 15);
    return this.expires * 1000 > now;
  }

  @action
  authCallback = (error, result) => {
    if (error) {
      this.setError(error.description);
    }
    if (result) {
      this.setSession(result);
    }
    this.loading = false;
  }

  @action
  setLoading = (loading = true) => {
    if (loading) this.clearError();
    this.loading = loading;
  }

  @action
  setError = (error) => {
    this.error = error;
  }

  @action
  setSuccessMessage = (message = null) => {
    this.successMessage = message;
  }

  @action('clear error')
  clearError = () => {
    this.error = null;
  }

  @action
  setUserId = async (data) => {
    this.userId = data;
  }

  @action
  setUser = async (data) => {
    this.user = JSON.stringify(data);
  }

  log = () => ({
    authenticated: this.authenticated,
    loggedIn: this.loggedIn,
    token: this.token,
    user: this.user,
    userId: this.userId,
    accessToken: this.accessToken,
    convertedToken: this.convertedToken,
  })
}

export default new AuthStore();
