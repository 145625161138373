export const getColor = (color = 'dark') => {
  switch (color) {
    case 'primary':
      return '#6EBF4C';
    case 'dark':
      return '#565656';
    case 'white':
      return '#FFFFFF';
    default:
      return color;
  }
};
