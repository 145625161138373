import { observable, action, computed } from 'mobx';
import { computedFn } from 'mobx-utils';
import api from 'api';

class TypesStore {
  @observable payment = {};
  @observable types = {};
  @observable filtersFacilitiesList = [];
  @observable filtersNursesList = [];

  constructor() {
    this.getTypes();
  }

  @action setTypes = data => {
    this.types = data;
  };

  @action setFiltersFacilityList = (data) => {
    this.filtersFacilitiesList = data;
  }

  @action setFiltersNursesList = (data) => {
    this.filtersNursesList = data;
  }

  @computed get nurseTypes() {
    return this.types.nurse_types || [];
  }

  @computed get covidChoices() {
    return this.types.covid_choices || [];
  }

  @computed get education() {
    return this.types.education;
  }

  @computed get certifications() {
    return this.types.certifications || [];
  }

  @computed get languages() {
    return this.types.languages;
  }

  @computed get assigmentStatuses() {
    return this.types.assignment_statuses;
  }

  @computed get accountTypes() {
    return this.types.account_types;
  }

  @computed get stateTypes() {
    return this.types.states;
  }

  @computed get roleTypes() {
    return this.types.roles;
  }

  nurseTypeById = computedFn(id => {
    const nurse = this.nurseTypes.find(e => e.id === id);
    return (nurse && nurse.title) || 'N/A';
  });

  nurseIdByType = computedFn(type => {
    const nurse = this.nurseTypes.find(e => e.type === type);
    return nurse && nurse.id;
  });

  statusById = computedFn(id => {
    const status = this.assigmentStatuses.find(e => e.id === id);
    return (status && status.title) || 'N/A';
  });


  @action async getPayment() {
    try {
      const res = await api.facility.account.payments();
      if (res.data) {
        this.payment = res.data;
      }
    } catch (err) {
      console.log(err);
    }
  }

  @computed get hasPayment() {
    return this.payment.card || (this.payment.bank && !this.payment.bank?.next_action) || this.payment.method === 3;
  }

  getTypes = async () => {
    const result = await api.types();
    if (result) {
      this.setTypes(result);
    }
  };

  getFilterNursesList = async (search, pageSize) => {
    try {
      const res = await api.platformAdmin.nurses.getFiltersList(search, pageSize);
      this.setFiltersNursesList(res.data.results);
    } catch (err) {
      console.log(`Can't get nurses`, err);
    }
  };
  getFilterFacilitiesList = async () => {
    try {
      const res = await api.platformAdmin.facilities.listForFilter();
      this.setFiltersFacilityList(res.data);
    } catch (err) {
      console.log(`Can't get facilities`, err);
    }
  }
}

export default new TypesStore();
