import React from 'react';
import { Row, Col } from 'antd';

import { Header } from 'components';
import userIcon from 'assets/images/icons/userIcon.svg';
import Time from 'helpers/Time';

import Item from './Item';

export const UserGeneralInfo = ({ user }) => {
  const isFA = user.role === 'Facility manager';
  const isNurse = user.role === 'Nurse';

  return (
    <div className="description">
      <Header type="h2" gutterBottom="lg">
        <img src={userIcon} alt="" style={{ paddingRight: 12 }} />
        <span>General Info</span>
      </Header>

      <Row gutter={[0, 32]}>
        <Col span={12}>
          <Item field="phone" >{user.phone || '-'}</Item>
        </Col>
        <Col span={12}>
          <Item field="email" >{user.email || '-'}</Item>
        </Col>
        {user.facility && (
          <Col span={12}>
            <Item field="facility">{user.facility || '-'}</Item>
          </Col>
        )}
        {isFA && (
          <>
            <Col span={12}>
              <Item field="facility_name">{user.profile && user.profile.name || '-'}</Item>
            </Col>
            <Col span={12}>
              <Item field="facility_address">{user.profile && user.profile.address || '-'}</Item>
            </Col>
            <Col span={12}>
              <Item field="facility_platform_fee">
                {user.profile?.platform_fee ? `${user.profile?.platform_fee}%` : 'None'}
              </Item>
            </Col>
          </>
        )}
        {!isFA && (
          <Col span={12}>
            <Item field="zip_code">{user.zip_code || '-'}</Item>
          </Col>
        )}
        <Col span={12}>
          <Item field="last_activity">
            {user.last_activity ? Time.formatDateFullMonth(user.last_activity) : '-'}
          </Item>
        </Col>
        {!isFA && (
          <>
            <Col span={12}>
              <Item field="languages">
                {user && user.profile && user.profile.languages && user.profile.languages.join(', ') || '-'}
              </Item>
            </Col>
            <Col span={12}>
              <Item field="education">
                {user.profile.education || '-'}
              </Item>
            </Col>
            {isNurse && (
              <Col span={12}>
                <Item field="covid19">
                  {(user && user.profile && user.profile.covid19 &&
                    ((user.profile.covid19 === 1 && 'Yes') || (user.profile.covid19 === 2 && 'No') ||
                      (user.profile.covid19 === 3 && 'Medical Exemption') || (user.profile.covid19 === 4 && 'Nonmedical Exemption')))}
                </Item>
              </Col>
            )}
          </>
        )}
      </Row>
    </div>
  );
};
