import React, { useState } from 'react';
import { DatePicker, Icon } from 'antd';
import Foco from 'react-foco';
import moment from 'moment';
import styled from 'styled-components';

import { fullDateUSFormat } from 'helpers/Time';
import { Button } from 'components';
import { icons } from 'assets/images';
import styles from './styles.module.scss';

const CleanIcon = styled(Icon)`
  font-size: 12px;
  position: absolute;
  right: 8px;
  top: 18px;
  color: rgba(0, 0, 0, 0.25);

  &:hover {
    color: rgba(0, 0, 0, 0.45);
  }
`;

const DatePickerButton = ({ value, onChange }) => {
  const [shown, setShown] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className={`${styles.dateBtnWrapper} custom-button`}
    >
      <Button
        small
        onClick={() => setShown(true)}
        className={styles.button}
      >
        <img src={icons.calendar2} alt="" />
        <span>{value ? `${moment(value[0]).format(fullDateUSFormat)} to ${moment(value[1]).format(fullDateUSFormat)}` : 'Date range'}</span>
      </Button>
      {isHovered && value && (
        <CleanIcon
          type="close-circle"
          theme="filled"
          onClick={() => onChange(null)}
        />
      )}
      <Foco onClickOutside={() => setShown(false)}>
        {shown && (
          <div className={styles.datePicker}>
            <DatePicker.RangePicker
              open
              value={value}
              onChange={dates => {
                const formatedDates = [
                  moment(dates[0]).startOf('day'),
                  moment(dates[1]).endOf('day'),
                ];
                if (onChange) {
                  onChange(formatedDates);
                }
                setShown(false);
                setIsHovered(false);
              }}
            />
          </div>
        )}
      </Foco>
    </div>
  );
};

export default DatePickerButton;
