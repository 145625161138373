import React from 'react';
import { getColor } from 'helpers/theme';

/* eslint-disable */
export const FilledStarIcon = ({ color }) => {
  const iconColor = getColor(color);
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.89695 0L10.3371 4.94349L15.7939 5.74108L11.8454 9.5869L12.7773 15.02L7.89695 12.4535L3.01663 15.02L3.94847 9.5869L0 5.74108L5.45679 4.94349L7.89695 0Z" fill={iconColor} />
    </svg>
  );
};
