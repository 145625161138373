export const formQueryParams = (params) =>
params
  ? `?${Object.keys(params)
    .filter(key => params[key])
    .map(key =>
      (key === 'nurse_type'|| key === 'status') && params[key].length > 1
        ? params[key].map(item => `${key}=${item}`).join('&')
        : `${key}=${params[key]}`)
    .join('&')
  }`
  : '';
