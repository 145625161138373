/* eslint-disable camelcase */
import React from 'react'
import { Route } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import Layout from './layout'

const Empty = observer(({ render, ...rest }) => (
  <Route
    {...rest}
    render={(matchProps) => <Layout>{render(matchProps)}</Layout>}
  />
))

export default Empty
