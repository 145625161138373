import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Redirect } from 'react-router-dom';
import { Layout } from 'antd';
import { Helmet } from 'react-helmet';

import { SIGN_IN } from 'routes';
import { useStore } from 'hooks';
import { FacilityLoggedInBillet } from 'components';

import { AppHeader } from '../components';
import s from './style.module.scss';

const { Header, Content } = Layout;
const AppLayout = observer(({ title, children }) => {
  const { auth, user } = useStore();

  if (!auth.authorized) {
    return <Redirect to={SIGN_IN} />;
  }

  useEffect(() => {
    user.getProfile(auth.isAdmin);
  }, []);

  return (
    <Layout style={{ minHeight: '100vh', paddingBottom: '24px' }}>
      {title && (
        <Helmet>
          <title>{title}</title>
        </Helmet>
      )}
      {auth.facilityName && <FacilityLoggedInBillet name={auth.facilityName} />}
      <Header style={{ background: '#575195', height: '80px', marginBottom: '24px' }}>
        <AppHeader />
      </Header>
      <Content style={{ overflow: 'initial' }}>
        <div className={s.content}>{children}</div>
      </Content>
    </Layout>
  );
});

export default AppLayout;
