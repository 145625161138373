import { formQueryParams } from 'helpers/formQueryParams';
import request from './kyMiddleware';

const assignments = {
  get: (params = {}) => request('get', `/facility-manager/assignments/${formQueryParams(params)}`),
};

const asigmentById = {
  get: id => request('get', `/facility-manager/assignments/${id}/`),
};

const assigment = {
  get: id => request('get', `/facility-manager/assignments/${id}/`),
  create: fields => request('post', '/facility-manager/assignments/', fields),
  update: (id, fields) => request('patch', `/facility-manager/assignments/${id}/`, fields),
  finalize: id => request('post', `/facility-manager/assignments/fill-finish/${id}/`),
  approveNurse: (assigmentId, nurseId) =>
    request('post', `/facility-manager/assignments/${assigmentId}/approve-nurse/${nurseId}/`),
  rejectNurse: (assigmentId, nurseId) =>
    request('post', `/facility-manager/assignments/${assigmentId}/reject-nurse/${nurseId}/`),
  approvePayment: (id, fields) =>
    request('post', `/facility-manager/assignments/approve-payment/${id}/`, fields),
  approveMultiPayment: (fields) => request('post', '/facility-manager/assignments/approve-multiple-payment/', fields),
  rate: (id, data) => request('post', `/facility-manager/assignments/feedback/${id}/`, data),
  reject: (id, data) => request('post', `/facility-manager/assignments/reject/${id}/`, data),
  getNurseRates: () => request('get', `/facility-manager/nurse-state-rates/`),
  cancel: (id) => request('post', `/facility-manager/assignments/cancel/${id}/`),
  blockNurse: (data) => request('post', '/facility-manager/black-list/', data),
  getNurseFeebacks: (params, id, assignmentId) =>
    request('get', `/facility-manager/assignments/${assignmentId}/nurse/${id}/feedback/${formQueryParams(params)}`),
  unassignNurse: (id) =>
    request('post', `/facility-manager/assignments/${id}/unassign-nurse/`),
};

const facilityProfile = {
  get: () => request('get', '/facility-manager/profile/'),
  put: fields => request('put', '/facility-manager/profile/', fields),
};

const nurse = {
  get: (id, assignmentId) => request('get', `/facility-manager/assignments/${assignmentId}/nurse/${id}/`),
};

const nurses = {
  getNursesList: (fields) => request('post', '/facility-manager/nurses/', fields),
};

const account = {
  account: fields => request('post', '/account/', fields),
  card: fields => request('post', '/account/card/', fields),
  seti: fields => request('post', '/account/ach-bank/', fields),
  removeCard: () => request('post', '/account/remove-card/'),
  removeBank: () => request('post', '/account/remove-bank/'),
  setLastError: fields => request('post', '/account/update-last-setup-error/', fields),
  clientSecret: fields => request('post', '/account/client-secret/', fields),
  payments: () => request('get', '/facility-manager/settings/payments/'),
  switchDefault: data => request('patch', '/facility-manager/payment-method/', data),
};

const organization = {
  get: () => request('get', '/facility-manager/settings/organization/'),
  put: fields => request('put', '/facility-manager/settings/organization/', fields),
};

const orientationDocuments = {
  get: () => request('get', '/facility-manager/settings/orientation-documents/'),
  getById: (id) => request('get', `/facility-manager/settings/orientation-documents/${id}/`),
  post: fields => request('post', '/facility-manager/settings/orientation-documents/', fields),
  update: (id, fields) => request('patch', `/facility-manager/settings/orientation-documents/${id}/`, fields),
  togglePublish: id => request('post', `/facility-manager/settings/orientation-documents/publish-toggle/${id}/`),
  delete: id => request('delete', `/facility-manager/settings/orientation-documents/${id}/`),
  attachment: {
    post: fields =>
      request('post', '/facility-manager/settings/orientation-document-attachments/', fields, {
        formData: true,
      }),
    update: (id, fields) => request('patch', `/facility-manager/settings/orientation-document-attachments/${id}/`, fields),
    delete: (id) => request('delete', `/facility-manager/settings/orientation-document-attachments/${id}/`),
  },
};

const blockedNurses = {
  get: () => request('get', '/facility-manager/black-list/'),
  remove: (id) => request('delete', `/facility-manager/black-list/${id}/`),
};

const notifications = {
  get: () => request('get', '/facility-manager/notifications/'),
  markRead: (data) => request('post', '/facility-manager/notifications/mark-read/', data),
};

const paymentHistory = {
  get: (params = {}) => request('get', `/facility-manager/payment-history/${formQueryParams(params)}`),
};

const employees = {
  get: (params = {}) => request('get', `/facility-manager/employees/${formQueryParams(params)}`),
  getById: (id) => request('get', `/facility-manager/employees/${id}/`),
  create: (data) => request('post', '/facility-manager/employees/', data),
  batchAdd: (data) => request(
    'post',
    '/facility-manager/employees-batch/',
    data,
    { formData: true, headers: { 'content-type': 'text/csv' },
  }),
  getFeedbacks: (params, id) => request('get', `/facility-manager/employees/${id}/feedback/${formQueryParams(params)}`),
  toggleActivity: (id) => request('post', `/facility-manager/employees/activate-toggle/${id}/`),
};

const facility = {
  assignments,
  assigment,
  asigmentById,
  profile: facilityProfile,
  organization,
  orientationDocuments,
  account,
  nurse,
  nurses,
  blockedNurses,
  notifications,
  paymentHistory,
  employees,
};

export default facility;
