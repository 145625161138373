import React, { Suspense, lazy } from 'react';
import { Switch, Route } from 'react-router-dom';

import { App, Auth } from 'layouts';

import {
  FORBIDDEN,
  NURSE,
  SIGN_IN,
  LOG_OUT,
  FORGET_PASSWORD,
  RESET_PASSWORD,
  ACTIVATE_ACCOUNT,
  MAIN,
  ACCOUNT,
  ASSIGMENT,
  ASSIGNMENTS,
  ASSIGMENT_CREATE,
  ASSIGMENT_EDIT,
  NURSE_APPLICATIONS,
  USERS,
  USER,
  SETTINGS,
  FACILITIES_LIST,
  PA_SETTINGS,
  FA_SETTINGS,
  FA_SETTINGS_BLOCKED_NURSES,
  PAYMENT_HISTORY,
  EMPLOYEES,
  EMPLOYEE,
  REPORTS,
} from './index';

const NotFound = lazy(() => import('pages/NotFound'));
const Forbidden = lazy(() => import('pages/Forbidden'));

const SignIn = lazy(() => import('pages/SignIn'));
const LogOut = lazy(() => import('pages/LogOut'));
const ForgetPassword = lazy(() => import('pages/ForgetPassword'));
const ResetPassword = lazy(() => import('pages/ResetPassword'));
const ActivateAccount = lazy(() => import('pages/ActivateAccount'));
const Home = lazy(() => import('pages/Home'));

const Assignments = lazy(() => import('pages/Assignments'));
const Account = lazy(() => import('pages/Account'));
const Settings = lazy(() => import('pages/Settings'));
const Assigment = lazy(() => import('pages/Assigment'));
const CreateAssigment = lazy(() => import('pages/CreateAssigment'));
const Facilities = lazy(() => import('pages/Facilities'));
const NurseApplications = lazy(() => import('pages/NurseApplications'));
const FaSettings = lazy(() => import('pages/FacilityManagerSettings'));
const BlockedNurses = lazy(() => import('pages/BlockedNurses'));
const PaSettings = lazy(() => import('pages/PlatformAdminSettings'));
const Nurse = lazy(() => import('pages/User'));
const Users = lazy(() => import('pages/Users'));
const User = lazy(() => import('pages/User'));
const Employee = lazy(() => import('pages/User'));
const Employees = lazy(() => import('pages/Employees'));
const PaymentHistory = lazy(() => import('pages/PaymentHistory'));
const Reports = lazy(() => import('pages/Reports'));

export default function Routes() {
  return (
    <Switch>
      <App title="Log out" exact path={LOG_OUT} render={LazyComponent(LogOut)} />
      <App exact path={MAIN} render={LazyComponent(Home)} />
      <App path={ASSIGNMENTS} render={LazyComponent(Assignments)} />
      <App exact path={SETTINGS} render={LazyComponent(Settings)} />
      <App exact title="Account" path={ACCOUNT} render={LazyComponent(Account)} />
      <App
        title="Payment History"
        exact
        path={PAYMENT_HISTORY}
        render={LazyComponent(PaymentHistory)}
      />

      <App fmOnly title="Nurse" path={NURSE} render={LazyComponent(Nurse)} />
      <App
        fmOnly
        exact
        title="Create Assignment"
        path={ASSIGMENT_CREATE}
        render={LazyComponent(CreateAssigment)}
      />
      <App fmOnly exact path={ASSIGMENT_EDIT} render={LazyComponent(CreateAssigment)} />
      <App exact path={`${ASSIGMENT}/:id`} render={LazyComponent(Assigment)} />
      <App fmOnly path={FA_SETTINGS} render={LazyComponent(FaSettings)} />
      <App
        fmOnly
        title="Blocked Nurses"
        path={FA_SETTINGS_BLOCKED_NURSES}
        render={LazyComponent(BlockedNurses)}
      />
      <App fmOnly title="Employees" exact path={EMPLOYEES} render={LazyComponent(Employees)} />
      <App fmOnly title="Employee" path={EMPLOYEE} render={LazyComponent(Employee)} />

      {/* Platform admin routes */}
      <App
        paOnly
        exact
        title="Facilities"
        path={FACILITIES_LIST}
        render={LazyComponent(Facilities)}
      />
      <App paOnly path={PA_SETTINGS} render={LazyComponent(PaSettings)} />
      <App
        paOnly
        title="Nurse Applications"
        path={NURSE_APPLICATIONS}
        render={LazyComponent(NurseApplications)}
      />
      <App paOnly exact title="Users" path={USERS} render={LazyComponent(Users)} />
      <App paOnly path={USER} render={LazyComponent(User)} />
      <App paOnly path={REPORTS} render={LazyComponent(Reports)} />

      {/* AUTH */}
      <Auth title="Login" exact path={SIGN_IN} render={LazyComponent(SignIn)} />
      <Auth
        title="Forgot Password"
        exact
        path={FORGET_PASSWORD}
        render={LazyComponent(ForgetPassword)}
      />
      <Auth
        doNotRedirect
        title="Reset Password"
        exact
        path={RESET_PASSWORD}
        render={LazyComponent(ResetPassword)}
      />
      <Auth
        title="Confirm Account"
        exact
        path={ACTIVATE_ACCOUNT}
        render={LazyComponent(ActivateAccount)}
      />

      <Route path={FORBIDDEN} render={LazyComponent(Forbidden)} />
      <Route render={LazyComponent(NotFound)} />
    </Switch>
  );
}

function LazyComponent(Component) {
  return props => (
    <Suspense fallback={<div />}>
      <Component {...props} />
    </Suspense>
  );
}
