import React from 'react';
import { getColor } from 'helpers/theme';

/* eslint-disable */
export const MarkAll = ({ color }) => {
  const iconColor = getColor(color);

  return (
    <span>
      <svg width="22" height="15" viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21.0085 1.96875L10.5378 13.982L6.2373 9.68154" stroke={iconColor} strokeWidth="1.375" strokeMiterlimit="10" strokeLinecap="square" />
        <path d="M5.81596 14.0287L1.46875 9.68152" stroke={iconColor} strokeWidth="1.375" strokeMiterlimit="10" strokeLinecap="square" />
        <path d="M16.2392 1.96875L10.3027 8.7934" stroke={iconColor} strokeWidth="1.375" strokeMiterlimit="10" strokeLinecap="square" />
      </svg>
    </span>
  );
};
