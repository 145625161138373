import React from 'react';
import classNames from 'classnames';
import { Spin, Icon } from 'antd';

import { history } from 'stores/routing';
import s from './Button.module.scss';

const AntIcon = () => (
  <Icon
    spin
    type="loading"
    style={{ fontSize: 24, color: '#6FC04D', width: '24px' }}
  />
);

const ButtonComponent = ({
  children,
  type = 'default',
  small,
  fullSize,
  to,
  loading,
  disabled,
  className,
  noPadding,
  onClick,
  submit,
  color,
  ...otherProps
}) => {
  const buttonType = {
    default: s.default,
    'default-green': s.defaultGreen,
    link: s.link,
    primary: s.primary,
    danger: s.danger,
    outlined: s.outlined,
  };
  return (
    <button
      type={submit ? 'submit' : 'button'}
      disabled={disabled || loading}
      className={classNames({
        [s.button]: true,
        [className]: className,
        [buttonType[type]]: true,
        [s.noPadding]: noPadding,
        [s.small]: small,
        [s.fullSize]: fullSize,
        'white': color === 'white',
        'red': color === 'red',
      })}
      onClick={(e) => {
        if (onClick) {
          onClick(e);
        } else if (to) {
          history.push(to);
        }
      }}
      {...otherProps}
    >
      <div className={s.content}>
        {loading && <Spin className={s.spinner} indicator={<AntIcon link={type === 'link'} />} />}
        <div className={classNames({ [s.loading]: loading })}>{children}</div>
      </div>
    </button>
  );
};

export default ButtonComponent;
