import React from 'react';
import { Menu, Button, Spin, Icon } from 'antd';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks';
import { MarkAll } from 'components';
import classes from './NotifMenu.module.scss';
import { Notification } from './NotificationItem.component';
import { Empty } from './Empty.component';

const AntIcon = () => (
  <Icon
    spin
    type="loading"
    style={{ fontSize: 24, color: '#6FC04D' }}
  />
);

const Spinner = () => (
  <div className={classes.spinner}>
    <Spin indicator={<AntIcon />} />
  </div>
);

export const NotificationsMenu = observer(({ hideMenu }) => {
  const { notifications: { history, isLoading, markAllAsRead, markAsReas } } = useStore();

  return (
    <Menu className={classes.menu}>
      {!isLoading && !history.length && <Empty />}
      {!isLoading && history.length && (
        <>
          <div className={classes.header}>
            <Button
              noPadding
              type="link"
              className={classes.button}
              onClick={() => markAllAsRead()}
            >
              <MarkAll color="#6EBF4C" />
              <span className={classes.buttonTitle}>
                mark all as read
              </span>
            </Button>
          </div>
          <div className={classes.notificationsList}>
            {history.length && history.slice().sort((a, b) => new Date(b.create) - new Date(a.create)).map((notification, index) => (
              <Notification
                notification={notification}
                key={notification.pk}
                lastItem={index === history.length - 1}
                hideMenu={hideMenu}
                markAsReas={markAsReas}
              />
            ))}
          </div>
        </>
      )}
      {isLoading && <Spinner />}
    </Menu>
  );
});
