import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import 'setup/initMobx';

import App from './App';
import * as serviceWorker from './serviceWorker';
import { StoreProvider } from './mobx';

require('dotenv').config();

ReactDOM.render(
  <BrowserRouter>
    <StoreProvider>
      <App />
    </StoreProvider>
  </BrowserRouter>, document.getElementById('root'),
);

// If you want your AppLayout to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
