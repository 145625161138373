import React from 'react';
import { getColor } from 'helpers/theme';

/* eslint-disable */
export const SadIcon = ({ color }) => {
  const iconColor = getColor(color);
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z" stroke={iconColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16.3996 16.4C16.3996 16.4 14.7496 14.2 11.9996 14.2C9.24961 14.2 7.59961 16.4 7.59961 16.4" stroke={iconColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.7002 8.70001H8.7112" stroke={iconColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15.2998 8.70001H15.3108" stroke={iconColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
