import { observable, action } from 'mobx';

class ViewStore {
  @observable loading = false

  @action setLoading = (loading = true) => {
    this.loading = loading;
  }
}

export default new ViewStore();
