import platformAdmin from './platformAdmin';
import facility from './facility';
import * as auth from './auth';
import types from './types';

const api = {
  types,
  facility,
  platformAdmin,
  auth,
};

export default api;
