
import React from 'react';
import { getColor } from 'helpers/theme';

/* eslint-disable */
export const NotesIcon = ({ color, style }) => {
  const iconColor = getColor(color);
  return (
    <svg style={style} width="28" height="35" viewBox="0 0 28 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.5 4.25H23.75C24.612 4.25 25.4386 4.59241 26.0481 5.2019C26.6576 5.8114 27 6.63805 27 7.5V30.25C27 31.112 26.6576 31.9386 26.0481 32.5481C25.4386 33.1576 24.612 33.5 23.75 33.5H4.25C3.38805 33.5 2.5614 33.1576 1.9519 32.5481C1.34241 31.9386 1 31.112 1 30.25V7.5C1 6.63805 1.34241 5.8114 1.9519 5.2019C2.5614 4.59241 3.38805 4.25 4.25 4.25H7.5" stroke={iconColor} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M18.875 1H9.125C8.22754 1 7.5 1.72754 7.5 2.625V5.875C7.5 6.77246 8.22754 7.5 9.125 7.5H18.875C19.7725 7.5 20.5 6.77246 20.5 5.875V2.625C20.5 1.72754 19.7725 1 18.875 1Z" stroke={iconColor} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
};
