import React, { useRef, useState, useEffect } from 'react';
import { get } from 'lodash';
import GeosuggestLib from 'react-geosuggest';
import $script from 'scriptjs';

import './Geosuggest.style.scss';

const mapsUrl = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_KEY}&libraries=places`;

export const Geosuggest = React.forwardRef(({ onChange, value, disabled }, ref) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const inputEl = useRef();

  useEffect(() => {
    $script(mapsUrl, () => setIsLoaded(true));
  }, []);

  const getState = mapData => {
    return new Promise((resolve) => {
      if (typeof mapData === 'string') {
        inputEl.current.geocoder.geocode({ address: mapData }, (results) => {
          const gmaps = results?.[0];
          if (gmaps) {
            const addressArray = gmaps.address_components;
            const address = addressArray.find(lvl => lvl.types.includes('administrative_area_level_1'));
            resolve(address ? address.short_name : false);
          }
        });
      } else {
        const addressArray = get(mapData, 'gmaps.address_components');
        if (!addressArray) {
          resolve(false);
        }

        resolve(addressArray.find(lvl => lvl.types.includes('administrative_area_level_1')).short_name);
      }
    });
  };

  return (
    <div ref={ref}>
      {isLoaded && (
        <GeosuggestLib
          country="us"
          disabled={disabled}
          ref={inputEl}
          initialValue={value?.title}
          onChange={(val) => onChange({ title: val, state: getState(val) })}
          onSuggestSelect={async (s = {}) => {
            onChange({
              title: s.label || '',
              state: getState(s),
            });
          }}
          inputClassName="ant-input"
        />
      )}
    </div>
  );
});
