import React, { useState } from 'react';
import { Modal, Form, Input } from 'antd';
import api from 'api';

import { Button, Notification } from 'components';

export const RejectModal = ({ form, assigmentId, element, onSuccess }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { getFieldDecorator } = form;

  const handleSubmit = () => {
    form.validateFields(async (validationErr, data) => {
      if (validationErr) {
        return;
      }

      setLoading(true);

      try {
        const result = await api.facility.assigment.reject(assigmentId, data);
        setOpen(false);
        Notification({
          message: 'Success',
          description: 'Payment was rejected.',
        });
        if (result.success && onSuccess) {
          onSuccess();
        }
      } catch (err) {
        Notification({
          type: 'error',
          message: 'Error',
          description: `Can't reject payment. Error message: ${err.message}.`,
        });
      } finally {
        setLoading(false);
      }
    });
  };

  return (
    <>
      <div
        onClick={() => setOpen(true)}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {element}
      </div>
      <Modal
        title={`Reject payment of assignment #${assigmentId}`}
        visible={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        footer={[
          <Button key="Cancel" onClick={() => setOpen(false)}>
            Cancel
          </Button>,
          <Button
            key="Rate"
            loading={loading}
            type="danger"
            onClick={handleSubmit}
          >
            Reject payment
          </Button>,
        ]}
      >
        <Form onSubmit={handleSubmit}>
          <Form.Item label="Reject reason">
            {getFieldDecorator('reason', {
              initialValue: '',
              rules: [
                { max: 255, message: 'Reject reason field shouldn’t contain more than 255 symbols' },
                { required: true, message: 'Please fill in reject reason' },
              ],
            })(<Input.TextArea rows={4} />)}
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export const RejectCompletedAssignmentModal =  Form.create({})(RejectModal);
