import React from 'react';
import { getColor } from 'helpers/theme';

/* eslint-disable */
export const ActiveAssignments = ({ color }) => {
  const iconColor = getColor(color);
  return (
    <svg width="26" height="28" viewBox="0 0 26 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.4 2.8H16.548C15.96 1.176 14.42 0 12.6 0C10.78 0 9.24 1.176 8.652 2.8H2.8C1.26 2.8 0 4.06 0 5.6V25.2C0 26.74 1.26 28 2.8 28H22.4C23.94 28 25.2 26.74 25.2 25.2V5.6C25.2 4.06 23.94 2.8 22.4 2.8ZM12.6 2.8C13.37 2.8 14 3.43 14 4.2C14 4.97 13.37 5.6 12.6 5.6C11.83 5.6 11.2 4.97 11.2 4.2C11.2 3.43 11.83 2.8 12.6 2.8ZM12.6 8.4C14.924 8.4 16.8 10.276 16.8 12.6C16.8 14.924 14.924 16.8 12.6 16.8C10.276 16.8 8.4 14.924 8.4 12.6C8.4 10.276 10.276 8.4 12.6 8.4ZM21 25.2H4.2V23.24C4.2 20.44 9.8 18.9 12.6 18.9C15.4 18.9 21 20.44 21 23.24V25.2Z"
        fill="#C8C8C8"
      />
    </svg>
  );
};
