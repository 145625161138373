import { observable, action } from 'mobx';
import api from 'api';
import { notification, message } from 'antd';

import auth from './auth.store';

const WSUrl = `${process.env.REACT_APP_HOST
  ? process.env.REACT_APP_HOST.replace('https', 'wss') : 'wss://test.gapnurse.com'}/ws`;
const getSocketUrl = () => `${WSUrl}/notifications/?token=${localStorage.getItem('token')}`;

class NotificationsStore {
  @observable history = [];
  @observable isLoading = false;

  @action setHistory = (data) => {
    this.history = data;
  };

  @action pushToHistory = (data) => {
    this.history.unshift(data);
  }

  @action setLoading = (val) => {
    if (val) {
      this.isLoading = val;
    } else {
      this.isLoading = !this.isLoading;
    }
  }

  constructor() {
    message.config({
      top: 85,
    });
    notification.config({
      placement: 'bottomRight',
      duration: 20,
    });
  }

  subscribe = () => {
    this.socket = new WebSocket(getSocketUrl());
    this.socket.onmessage = (event) => {
      let data = {};
      try {
        const parsed = JSON.parse(event.data);
        data = {
          ...parsed,
          created: parsed.time,
          pk: parsed.notification_pk,
        };
      } catch { } //eslint-disable-line
      message.open({
        content: data.msg,
        duration: 8,
      });
      this.pushToHistory(data);
    };
  }

  unsubscribe = () => {
    this.socket.close();
  }

  fetchHistory = () => {
    this.setLoading(true);
    (
      auth.isAdmin
        ? api.platformAdmin.notifications
        : api.facility.notifications
    ).get().then(({ data }) => {
      if (data && data.results) {
        this.setHistory(data.results);
      }
      this.setLoading(false);
    });
  }

  @action markAsReas = async (notifications) => {
    (
      auth.isAdmin
        ? api.platformAdmin.notifications
        : api.facility.notifications
    ).markRead({ notifications })
      .then(() => {
        this.history = this.history.map(item => {
          if (notifications.some((notif) => notif === item.pk)) {
            item.read = true;
          }
          return item;
        });
      });
  }

  markAllAsRead = () => {
    this.setLoading();
    this.markAsReas(this.history.map(({ pk }) => pk))
      .then(() => this.fetchHistory())
      .catch(() => this.setLoading());
  }
}

export default new NotificationsStore();
