import React from 'react';
import Sticky from 'react-stickynode';

import s from './style.module.scss';

const Page = ({
  children,
  title,
  actions,
  centralTabs,
  filters,
  contentsWithoutPadding,
}) => (
  <div className={s.wrapper}>
    <Sticky innerZ={1000} enabled top={0} bottomBoundary={1200}>
      <div className={`${s.heading} sticky-header`}>
        <div className={s.title}>{title}</div>
        <div className={s.centralTabs} id="page-central-tabs-container">{centralTabs}</div>
        <div className={s.controls} id="page-controls-container">
          {actions}
        </div>
      </div>
    </Sticky>
    <div className={!contentsWithoutPadding && s.body}>
      {filters && (
        <div className={`${s.filters} ant-form`}>
          {filters}
        </div>
      )}
      <div className={s.tableContainer}>
        {children}
      </div>
    </div>
  </div>
);

export default Page;
