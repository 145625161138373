import styled from 'styled-components';

const Container = styled.div`
  & + & {
    margin-bottom: 20px;
  }

  .description {
    color: #8a949a;
    font-size: 13px;
  }

  .caption {
    color: #565656;
    font-weight: 500;
    font-size: 18px;
  }

  .item {
    padding-left: 42px;
  }
`;

export default Container;
