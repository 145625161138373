import React from 'react';
import { Row, Col } from 'antd';

import {
  Header,
  TotalAssignment,
  ActiveAssignments,
  CompleteAssignments,
  AmountEarned,
} from 'components';
import { useStore } from 'hooks';

import CardDetails from './CardDetails';

export const NurseDetails = ({ user }) => {
  const { auth: { isAdmin } } = useStore();

  return (
    <>
      <Header type="h2" gutterBottom="lg">
        Statistic
      </Header>

      <Row gutter={10}>
        {isAdmin && (
          <>
            <Col className="gutter-row" span={6}>
              <CardDetails
                field="total_assignments"
                value={user.total_assignments}
                iconComp={<TotalAssignment size={26} color="#C8C8C8" />}
              />
            </Col>
            <Col className="gutter-row" span={6}>
              <CardDetails
                field="active_assignment"
                value={user.active_assignments}
                iconComp={<ActiveAssignments size={26} color="#C8C8C8" />}
              />
            </Col>
          </>
        )}
        <Col className="gutter-row" span={6}>
          <CardDetails
            field="complete_assignment"
            value={user.complete_assignments}
            iconComp={<CompleteAssignments size={26} color="#C8C8C8" />}
          />
        </Col>
        {isAdmin && (
          <Col className="gutter-row" span={6}>
            <CardDetails
              field="amount_earned"
              value={user.amount_earned ? `$${user.amount_earned.toFixed(2)}` : null}
              iconComp={<AmountEarned size={26} color="#C8C8C8" />}
            />
          </Col>
        )}
      </Row>
    </>
  );
};
