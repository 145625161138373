import React, { useRef, useState } from 'react';
import { Popover, Input, Icon } from 'antd';
import DayPicker, { DateUtils } from 'react-day-picker/DayPicker';
import 'react-day-picker/lib/style.css';
import moment from 'moment';
import { fullDateUSFormat } from 'helpers/Time';
import Button from 'components/Button/index';

const DatePicker = (
  {
    onChange,
    value,
    disabled,
    disableFuture,
    disablePast = new Date(),
    multiple = true,
    handleCheckNurses,
  },
  ref,
) => {
  const [selectedDays, setSelectedDays] = React.useState(value || []);
  const pickerRef = useRef();
  const [popoverVisible, setPopoverVisible] = useState(false);

  const setValue = (dates) => {
    const newDates = dates.sort((a, b) => new Date(a) - new Date(b));
    setSelectedDays(newDates);
    onChange(newDates);
    handleCheckNurses && handleCheckNurses({ dates: newDates });
  };
  const handleDayClick = (day, { selected, disabled: dayDisabled }) => {
    if (dayDisabled) {
      return;
    }

    if (selected) {
      setValue(selectedDays.filter((selectedDay) => !DateUtils.isSameDay(selectedDay, day)));
    } else if (multiple) {
      setValue([...selectedDays, day]);
    } else {
      setValue([day]);
    }
  };

  const handleClear = (event) => {
    if (!event.target.value) {
      setValue([]);
    }
  };

  let label = '';

  if (selectedDays.length === 1) {
    label = moment(selectedDays[0]).format(fullDateUSFormat);
  } else if (selectedDays.length === 2) {
    label = `${moment(selectedDays[0]).format(fullDateUSFormat)}, ${moment(
      selectedDays[selectedDays.length - 1],
    ).format(fullDateUSFormat)}`;
  } else if (selectedDays.length > 2) {
    label = `${selectedDays.length} days (${moment(selectedDays[0]).format(
      fullDateUSFormat,
    )} to ${moment(selectedDays[selectedDays.length - 1]).format(fullDateUSFormat)})`;
  }

  const input = (
    <Input
      allowClear
      disabled={disabled}
      value={label}
      onChange={handleClear}
      suffix={<Icon type="calendar" />}
    />
  );

  return disabled ? (
    input
  ) : (
    <div ref={ref}>
      <Popover
        placement="bottom"
        content={
          <>
            <DayPicker
              ref={pickerRef}
              showOutsideDays
              todayButton="Go to Today"
              disabledDays={{
                ...(disablePast ? { before: disablePast } : {}),
                ...(disableFuture ? { after: disableFuture } : {}),
              }}
              value={selectedDays}
              selectedDays={selectedDays}
              onDayClick={handleDayClick}
            />
            <Button
              style={{ height: 30, width: 40, marginLeft: -60 }}
              onClick={() => setPopoverVisible(false)}
            >
              Ok
            </Button>
          </>
        }
        trigger="click"
        visible={popoverVisible}
        onVisibleChange={setPopoverVisible}
      >
        <div style={{ cursor: 'pointer' }}>{input}</div>
      </Popover>
    </div>
  );
};

export default React.forwardRef(DatePicker);
