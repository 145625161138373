import React from 'react';
import classnames from 'classnames';
import classes from './styles.module.scss';

const elements = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
};

export const Header = ({ superLarge, gutterTop = null, gutterBottom = 'sm', children, className, type = 'h1', ...rest }) => {
  return React.createElement(
    elements[type],
    {
      className: classnames(
        className,
        classes.header,
        classes[`gutter-bottom__${gutterBottom}`],
        classes[`gutter-top__${gutterTop}`],
        {
          [classes.superLarge]: superLarge,
        },
      ),
      ...rest,
    },
    children,
  );
};
