import React from 'react';
import { getColor } from 'helpers/theme';

/* eslint-disable */
export const NextIcon = ({ color, style }) => {
  const iconColor = getColor(color);
  return (
    <svg
      style={style}
      width="11"
      height="18"
      viewBox="0 0 11 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 0.999999L9.5 9L1.5 17"
        stroke={iconColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
