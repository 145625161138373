import { useEffect } from 'react';
import ReactDOM from 'react-dom';

export const PageCentralTabsPortal = ({ children }) => {
  const el = document.createElement('div');

  useEffect(() => { // eslint-disable-line consistent-return
    const controlsContainer = document.getElementById('page-central-tabs-container');
    if (controlsContainer) {
      controlsContainer.appendChild(el);
      return () => {
        controlsContainer.removeChild(el);
      };
    }
  }, [children, el]);

  return ReactDOM.createPortal(
    children,
    el,
  );
};
