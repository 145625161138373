import React from 'react';
import { observer } from 'mobx-react-lite';

import classes from './styles.module.scss';

const labels = {
  total_assignments: 'Total assignments',
  active_assignment: 'Active assignments',
  complete_assignment: 'Completed assignments',
  amount_earned: 'Amount earned',
};

export const CardDetails = ({ field, iconComp, value }) => (
  <div className={classes.container}>
    <div className={classes.body}>
      <div className={classes.info}>
        <div className={classes.icon}>{iconComp}</div>
        <div className={classes.total}>{labels[field] ? labels[field] : field}</div>
        <div className={classes.value}>{value || '-'}</div>
      </div>
    </div>
  </div>
);

export default observer(CardDetails);
