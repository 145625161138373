import React from 'react';
import { getColor } from 'helpers/theme';

/* eslint-disable */
export const PlusSimpleIcon = ({ color }) => {
  const iconColor = getColor(color);
  return (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.5 1.5V11.5" stroke={iconColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M1.5 6.5H11.5" stroke={iconColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
