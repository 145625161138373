import styled from 'styled-components';

export const ErrorPage = styled.div`
  display: flex;
  align-items: center;
  height: 100vh;
  padding: 150px;
  ${({ justify }) => justify && `justify-content: ${justify}`};
  ${({ direction }) => direction && `flex-direction: ${direction}`};
  background: #F3F5FA;
`;
