import React from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';

import { ErrorPage, Button, Header } from 'components';

import img from './error.svg';

const Caption = styled.p`
  font-size: 23px;
  color: #414141;
  margin-bottom: 40px;
`;

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) { // eslint-disable-line
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <ErrorPage justify="center" direction="column">
          <Row style={{ width: '100%' }}>
            <Col span={12}>
              <Header style={{ color: '#C5C8D6' }} superLarge gutterBottom="xs">Ooops...</Header>
              <Caption>Something went wrong</Caption>
              <Button
                type="primary"
                onClick={() => { document.location.href = '/'; }}
              >
                Go to the main page
              </Button>
            </Col>
            <Col span={12}>
              <img style={{ width: '100%' }} src={img} alt="" />
            </Col>
          </Row>
        </ErrorPage>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
