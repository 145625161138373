import React from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';
import * as Icons from 'components/Icons';
import s from './HeaderAction.module.scss';

const getIconByName = name => {
  return Icons[name];
};

function HeaderAction({ to, icon, iconName, children, ...restProps }) {
  const match = useRouteMatch(to);
  const IconByName = getIconByName(iconName);

  return (
    <NavLink to={to} className={s.wrapper} activeClassName={s.active} {...restProps}>
      <div className={s.content}>
        <IconByName color={match ? 'primary' : 'white'} />
        <div className={s.label}>{children}</div>
      </div>
    </NavLink>
  );
}

export default HeaderAction;
