import React from 'react'
import { observer } from 'mobx-react-lite'
import { Icon } from 'antd'
import s from './style.module.scss'

const OptionalField = observer(({ toggledText, children, handleClick, active }) => {
  if (active) return children

  return (
    <div onClick={handleClick} className={s.addField}>
      <div className={s.plus}>
        <Icon type="plus-circle" theme="filled" />
      </div>
      <div className={s.text}>{toggledText}</div>
    </div>
  )
})

export default OptionalField
