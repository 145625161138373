import React from 'react';
import { getColor } from 'helpers/theme';

/* eslint-disable */
export const Employees = ({ color }) => {
  const iconColor = getColor(color);
  return (
    <svg width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.6667 22.6407V20.3074C19.6667 19.0697 19.175 17.8828 18.2998 17.0076C17.4247 16.1324 16.2377 15.6407 15 15.6407H5.66667C4.42899 15.6407 3.242 16.1324 2.36683 17.0076C1.49167 17.8828 1 19.0697 1 20.3074V22.6407"
        stroke={iconColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.3342 10.9741C12.9116 10.9741 15.0009 8.88474 15.0009 6.30741C15.0009 3.73008 12.9116 1.64075 10.3342 1.64075C7.75691 1.64075 5.66757 3.73008 5.66757 6.30741C5.66757 8.88474 7.75691 10.9741 10.3342 10.9741Z"
        stroke={iconColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.6669 22.6407V20.3074C26.6661 19.2734 26.322 18.2689 25.6885 17.4517C25.055 16.6345 24.168 16.0509 23.1669 15.7924"
        stroke={iconColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.4993 1.79248C19.5031 2.0495 20.3929 2.6333 21.0282 3.45184C21.6636 4.27039 22.0085 5.27711 22.0085 6.31331C22.0085 7.34951 21.6636 8.35624 21.0282 9.17478C20.3929 9.99333 19.5031 10.5771 18.4993 10.8341"
        stroke={iconColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
