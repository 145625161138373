import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'

const FieldsResolver = observer(({ children }) => {
  const [activeFields, setActiveFields] = useState([])

  const renderField = name => {
    const field = children[name]
    const active = activeFields.includes(name)
    return field(active, () => setActiveFields([...activeFields, name]))
  }

  return (
    <div>
      {Object.keys(children)
        .filter(name => activeFields.includes(name))
        .map(name => renderField(name))}
      {Object.keys(children)
        .filter(name => !activeFields.includes(name))
        .map(name => renderField(name))}
    </div>
  )
})

export default FieldsResolver
