import { formQueryParams } from 'helpers/formQueryParams';
import request from './kyMiddleware';

const assignments = {
  get: (params = {}) => request('get', `/platform-admin/assignments/${formQueryParams(params)}`),
  getById: (id) => request('get', `/platform-admin/assignments/${id}/`),
  resetDecision: (id) => request('post', `/platform-admin/assignments/${id}/reset/`),
  update: (id, data) => request('patch', `/platform-admin/assignments/${id}/`, data),
  makePaid: (id) =>
    request('post', `/platform-admin/assignments/${id}/make-paid/`),
};

const profile = {
  get: () => request('get', '/platform-admin/profile/'),
  update: (data) => request('put', '/platform-admin/profile/', data),
};

const facilities = {
  get: (params) => request('get', `/platform-admin/facility/${formQueryParams(params)}`),
  getById: (id) => request('get', `/platform-admin/facility/${id}/`),
  create: (data) => request('post', '/platform-admin/facility/', data),
  update: (id, data) => request('patch', `/platform-admin/facility/${id}/`, data),
  getManagers: (id, data) => request('get', `/platform-admin/facility/${id}/add_manager/`, data),
  createManager: (id, data) => request('put', `/platform-admin/facility/${id}/add_manager/`, data),
  updateManager: (id, data) => request('patch', `/platform-admin/facility/${id}/add_manager/`, data),
  listForFilter: () => request('get', '/platform-admin/facility-list/'),
  loginAsFacility: id => request('post', `/platform-admin/login_as_manager/${id}/`),
  logoutFromFacility: () => request('post', '/platform-admin/logout_as_manager/'),
};

const nurseRates = {
  get: () => request('get', '/platform-admin/state-rates/'),
  update: (id, data) => request('put', `/platform-admin/state-rates/${id}/`, data),
};

const users = {
  get: (params = {}) => request('get', `/platform-admin/users/${formQueryParams(params)}`),
  getById: (id) => request('get', `/platform-admin/users/${id}/`),
  toggleActivity: (id) => request('post', `/platform-admin/user/activate-toggle/${id}/`),
  getUserFeedback: (paginationParams, id) => request('get', `/platform-admin/users/${id}/feedback/${formQueryParams(paginationParams)}`),
};

const trainings = {
  get: () => request('get', '/platform-admin/trainings/'),
  getById: (id) => request('get', `/platform-admin/trainings/${id}/`),
  create: (data) => request('post', '/platform-admin/trainings/', data),
  update: (id, data) => request('patch', `/platform-admin/trainings/${id}/`, data),
};

const nurseApplications = {
  get: (params) => request('get', `/platform-admin/nurses/${formQueryParams(params)}`),
  approve: (id) => request('post', `/platform-admin/nurses/approve/${id}/`),
  reject: (id, body) => request('post', `/platform-admin/nurses/reject/${id}/`, body),
  getHistory: (params) => request('get', `/platform-admin/nurses/history/${formQueryParams(params)}`),
};

const notifications = {
  get: () => request('get', '/platform-admin/notifications/'),
  markRead: (data) => request('post', '/platform-admin/notifications/mark-read/', data),
};

const nurses = {
  getFiltersList: (search = '', pageSize = 10) =>
    request('get', `/platform-admin/nurse-list/?search=${search}&page_size=${pageSize}`),
};

const paymentHistory = {
  get: (params = {}) => request('get', `/platform-admin/payment-history/${formQueryParams(params)}`),
};

const reports = {
  get: (params = {}) => request('get', `/platform-admin/reports/${formQueryParams(params)}`),
  create: (data) => request('post', '/platform-admin/reports/', data),
};

const platformAdmin = {
  profile,
  facilities,
  assignments,
  nurseRates,
  users,
  trainings,
  nurseApplications,
  nurses,
  paymentHistory,
  notifications,
  reports,
};

export default platformAdmin;
