import React from 'react';
import { observer } from 'mobx-react-lite';

import { getMediaPath } from 'helpers/getMediaPath';
import { avatar_default } from 'assets/images';
import { Button, ControlsPortal, FilledStarIcon } from 'components';

import { ApplicationActions } from '../ApplicationActions';
import classes from './styles.module.scss';

const getRoleString = (user) => {
  if (user.role === 'Nurse') {
    return user.profile.type;
  }

  return user.role;
};

export const UserCard = observer(({ user, toggleActivity, ChangeFacilityOwner }) => {
  const photo = (user.profile && user.profile.photo && getMediaPath(user.profile.photo))
    || (user.profile && user.profile.photoPath)
    || avatar_default;

  return (
    <>
      {user.status === 'Applied' && (
        <ControlsPortal>
          <ApplicationActions pk={user.pk} user={user} />
        </ControlsPortal>
      )}
      <div className={classes.container}>
        <div className={classes.picture}>
          <img src={photo} alt="" />
        </div>
        <div className={classes.body}>
          <div className={classes.info}>
            <div className={classes.name}>{`${user.first_name} ${user.last_name}`}</div>
            {user.average_score && (
              <div className={classes.rateContainer}>
                <FilledStarIcon />
                <span className={classes.rate}>{user.average_score}</span>
              </div>
            )}
            <div className={classes.role}>{getRoleString(user)}</div>
            {user.employer && (
              <div className={classes.role}>
                Employee of:
                {' '}
                {user.employer}
              </div>
            )}
          </div>

          {ChangeFacilityOwner && (<div style={{ marginBottom: 15 }}><ChangeFacilityOwner /></div>)}

          {toggleActivity && (
            user.is_active ? (
              <Button fullSize type="danger" onClick={toggleActivity}>
                Deactivate
              </Button>
            ) : (
              <Button fullSize type="primary" onClick={toggleActivity}>
                Activate
              </Button>
            )
          )}
        </div>
      </div>
    </>
  );
});
