import React from 'react';
import { getColor } from 'helpers/theme';

/* eslint-disable */
export const Home = ({ color }) => {
  const iconColor = getColor(color);
  return (
    <svg width="21" height="23" viewBox="0 0 21 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 8.35L10.45 1L19.9 8.35V19.9C19.9 20.457 19.6787 20.9911 19.2849 21.3849C18.8911 21.7787 18.357 22 17.8 22H3.1C2.54305 22 2.0089 21.7787 1.61508 21.3849C1.22125 20.9911 1 20.457 1 19.9V8.35Z"
        stroke={iconColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.29999 22V11.5H13.6V22"
        stroke={iconColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
