import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Select } from 'antd';

import { useStore } from 'hooks';

export const FacilitySelect = observer(({ value, onChange }) => {
  const { types } = useStore();

  useEffect(() => {
    types.getFilterFacilitiesList();
  }, [types]);

  return (
    <Select
      getPopupContainer={trigger => trigger.parentNode}
      showSearch
      allowClear
      placeholder="Facility"
      style={{ width: 120 }}
      onChange={onChange}
      dropdownMatchSelectWidth={false}
      value={value || undefined}
      filterOption={
        (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {
        types.filtersFacilitiesList
        && types.filtersFacilitiesList
        && types.filtersFacilitiesList.filter
        && types.filtersFacilitiesList
          .filter((facility) => !!facility)
          .map(option => (
            <Select.Option key={option.pk} value={option.pk}>{option.name}</Select.Option>
          ))
      }
    </Select>
  );
});
