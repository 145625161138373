import React from 'react';
import Sticky from 'react-stickynode';

import { history } from 'stores/routing';
import { BackButton } from 'components';
import styles from './styles.module.scss';

export const PageWithAside = (
  { centralTabs, title, aside, content, children, withFormSizeLimiter, withBackButton, controls, isFacility }
) => (
  <div className={styles.wrapper}>
    <Sticky innerZ={1000} enabled top={0} bottomBoundary={1200}>
      <header className={`${styles.heading} sticky-header`}>
        <div className={styles.title}>
          {withBackButton && (<BackButton onClick={() => history.goBack()} className="back-button" />)}
          {title}
        </div>
        <div className={styles.centralTabs} id="page-central-tabs-container">{centralTabs}</div>
        <div className={styles.controls} id="page-controls-container">
          {controls}
        </div>
      </header>
    </Sticky>
    {isFacility
      ? (
        <div className={styles.isFacility}>
          {content}
        </div>
      )
      : (
        <div className={styles.body}>
          {aside && (
            <aside className={styles.aside}>
              {aside}
            </aside>
          )}
          <div className={styles.content}>
            {(withFormSizeLimiter && !isFacility)
              ? (
                <div className={styles.form}>
                  {children || content}
                </div>
              ) : (
                children || content
              )}
          </div>
        </div>
      )}
  </div>
);
