import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const ViewTab = styled(NavLink)`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: #565656;
  white-space: nowrap;

  &.active {
    color: #6FC04D;
  }
`;
