import React from 'react';
import { notification, Icon } from 'antd';

import success from './success.svg';
import close from './close.svg';

const Notification = ({ message, description, type = 'success', duration }) => {
  const key = new Date().getTime();

  let icon;

  switch (type) {
    case 'success':
      icon = <img src={success} alt="" />;
      break;
    case 'info':
      icon = <Icon type="info-circle" style={{ fontSize: '35px', color: '#6FC04D' }} />;
      break;
    default:
      icon = null;
      break;
  }

  notification.open({
    type,
    key,
    message,
    description,
    style: {
      boxShadow: '0px 4px 34px rgba(68, 153, 169, 0.1)',
      borderRadius: '8px',
    },
    duration: duration || 8,
    icon,
    btn: (
      <div onClick={() => notification.close(key)}>
        <img src={close} alt="" />
      </div>
    ),
  });
};

export default Notification;
