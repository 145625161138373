import React from 'react';
import { NavLink as NavLinkComp, useRouteMatch } from 'react-router-dom';
import classnames from 'classnames';

import * as Icons from 'components/Icons';
import classes from './style.module.scss';

const getIconByName = (name) => {
  return Icons[name];
};

export const NavLink = ({ to, icon, iconName, title, green }) => {
  const match = useRouteMatch(to);
  const IconByName = getIconByName(iconName);

  return (
    <NavLinkComp
      to={to}
      className={classnames(classes.link, { [classes.green]: green })}
      activeClassName={classes.activeLink}
    >
      <div className={classes.content}>
        {iconName && (
          <div className={classes.iconContainer}><IconByName color={match ? 'primary' : 'dark'} /></div>
        )}
        {icon && (
          <div className={classes.iconContainer}>{icon}</div>
        )}
        <span>{title}</span>
      </div>
    </NavLinkComp>
  );
};
