import ky from 'ky';

const getTypes = () => {
  try {
    return ky
      .get('types-and-statuses/', {
        method: 'GET',
        prefixUrl: process.env.REACT_APP_API_ENDPOINT,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .json();
  } catch (e) {
    return null;
  }
};

export default getTypes;
