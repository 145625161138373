import React from 'react';
import { InputNumber as InputNumberLib } from 'antd';
import s from './InputNumber.module.scss';

function InputNumber({ value, onChange, prefix, disabled, ...props }, ref) {
  return (
    <div className={s.container} ref={ref}>
      <InputNumberLib
        {...props}
        type="text"
        name=""
        disabled={disabled}
        value={value}
        onChange={onChange}
        className={prefix ? s.inputWithPrefix : null}
      />
      {prefix && <div className={s.prefix}>{prefix}</div>}
    </div>
  );
}

export default React.forwardRef(InputNumber);
