import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'antd';
// import s from './styles.module.scss';

export const TableContainer = ({minWidth, children}) => {
  const tableRef = useRef();
  const [showArrows, setShowArrows] = useState(false);

  useEffect(() => {
    if (tableRef?.current?.offsetWidth) {
      setShowArrows(tableRef?.current?.offsetWidth < (minWidth || 1250));
    }
  }, [tableRef?.current]);

  const handleScrollLeft = () => {
    tableRef.current.scrollLeft += 150;
  };

  const handleScrollRight = () => {
    tableRef.current.scrollLeft += -150;
  };

  return (
    <div ref={tableRef} style={{ overflowX: 'auto' }}>
      {showArrows && (
        <>
          <div style={{ position: 'absolute', top: 330, left: 0, zIndex: 900 }}>
            <Button size="large" shape="circle" icon="left" onClick={handleScrollRight} />
          </div>
          <div style={{ position: 'absolute', top: 330, right: 0, zIndex: 900 }}>
            <Button size="large" shape="circle" icon="right" onClick={handleScrollLeft} />
          </div>
        </>
  )}
      {children}
    </div>
  );
};
