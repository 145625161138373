import calendar2 from './calendar2.svg';
import employees from './employees.svg';
import patient from './patient.svg';
import userIcon from './userIcon.svg';
import payment from './payment.svg';
import document  from './document.svg';
import certifications  from './certifications.svg';

export default {
  calendar2,
  employees,
  patient,
  userIcon,
  payment,
  document,
  certifications,
};
