import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { useStore } from 'hooks';
import eyeIcon from 'assets/images/icons/eye-icon.svg';
import { Button } from '..';

const Wrapper = styled.div`
  background: #6ebf4c;
  text-align: center;
  color: white;
  justify-content: center;
  align-items: center;
  height: 45px;
  display: flex;
  font-weight: 500;
`;

export const FacilityLoggedInBillet = observer(({ name = 'Blabla' }) => {
  const { auth } = useStore();
  const [isLoading, setIsLoading] = React.useState(false);

  return (
    <Wrapper>
      <img style={{ marginBottom: 4, marginRight: 6 }} src={eyeIcon} alt="" />
      <span>
        Viewing
        {' '}
        {name}
        {' '}
        facility
      </span>
      <Button
        loading={isLoading}
        style={{ marginLeft: 20 }}
        type="outlined"
        onClick={() => {
          setIsLoading(true);
          auth.logoutFromFacility().finally(() => {
            setIsLoading(false);
          });
        }}
      >
        Back to admin view
      </Button>
    </Wrapper>
  );
});
