import React from 'react';
import { getColor } from 'helpers/theme';

/* eslint-disable */
export const Assignments = ({ color }) => {
  const iconColor = getColor(color);
  return (
    <svg
      width="33"
      height="28"
      stroke={iconColor}
      viewBox="0 0 33 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.1355 26.3625V23.5444C22.1355 22.0496 21.5417 20.616 20.4847 19.5591C19.4278 18.5021 17.9942 17.9083 16.4994 17.9083H6.63614C5.14134 17.9083 3.70777 18.5021 2.65079 19.5591C1.59381 20.616 1 22.0496 1 23.5444V26.3625"
        stroke={iconColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5688 12.2723C14.6815 12.2723 17.2049 9.74889 17.2049 6.63614C17.2049 3.52338 14.6815 1 11.5688 1C8.456 1 5.93262 3.52338 5.93262 6.63614C5.93262 9.74889 8.456 12.2723 11.5688 12.2723Z"
        stroke={iconColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.7709 8.04517V16.4994"
        stroke={iconColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.9999 12.2719H23.5457"
        stroke={iconColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
