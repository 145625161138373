import React, { useState, useEffect } from 'react';
import { Dropdown, Badge } from 'antd';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks';
import notifications from 'assets/images/icons/bell.svg';

import { NotificationsMenu } from './NotificationsMenu';
import classes from './styles.module.scss';

export const Notifications = observer(() => {
  const [isNotificationsMenuVisible, setIsNotificationsMenuVisible] = useState(false);
  const {
    notifications: {
      fetchHistory,
      history,
      subscribe,
      unsubscribe,
    },
  } = useStore();

  useEffect(() => {
    fetchHistory();
    subscribe();
    return () => unsubscribe();
  }, []);

  return (
    <Dropdown
      getPopupContainer={trigger => trigger.parentNode}
      overlay={(
        <NotificationsMenu hideMenu={() => setIsNotificationsMenuVisible(false)} />
      )}
      placement="bottomCenter"
      size="large"
      className={classes.dropdown}
      trigger={['click']}
      visible={isNotificationsMenuVisible}
      onVisibleChange={(shown) => setIsNotificationsMenuVisible(shown)}
    >
      <div className={classes.actionNotify}>
        <img src={notifications} alt="" />
        {history.some((notif) => !notif.read) && (
          <Badge style={{ marginBottom: 20 }} color="green" />
        )}
      </div>
    </Dropdown>
  );
});
