import React from 'react';
import { Button as LibButton } from 'antd';
import styled from 'styled-components';

const Button = styled(LibButton)`
  &:hover,
  &:active,
  &:focus {
    border-color: #6EBF4C;
    color: #6EBF4C;
  }
`;

export const RoundButton = ({ children, icon, onClick }) => (
  <Button shape="circle" icon={icon} onClick={onClick}>{children}</Button>
);
