import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Rate } from 'antd';
import api from 'api';
import { get } from 'lodash';

import { Button, Notification } from 'components';

export const RateAssignmentModalComp = ({ form, assigmentId, element, onSuccess }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [assignmentData, setAssignmentData] = useState({});
  const { getFieldDecorator } = form;

  useEffect(() => {
    if (open) {
      const fetchData = async () => {
        try {
          const res = await api.facility.assigment.get(assigmentId);
          setAssignmentData(res.data);
        } catch (err) {
          Notification({
            type: 'error',
            message: 'Error',
            description: `Error while loading assignment info. Error message: ${err.message}.`,
          });
        }
      };
      fetchData();
    }
  }, [open]);

  const handleSubmit = () => {
    form.validateFields(async (validationErr, { feedback, rating }) => {
      if (validationErr) {
        return;
      }

      setLoading(true);

      try {
        const result = await api.facility.assigment.rate(assigmentId, {
          feedback,
          rating,
        });
        setOpen(false);
        Notification({
          message: 'Success',
          description: 'Nurse rate was sent.',
        });
        if (result.success && onSuccess) {
          onSuccess();
        }
      } catch (err) {
        Notification({
          type: 'error',
          message: 'Error',
          description: `Can't rate assignment. Error message: ${err.message}.`,
        });
      } finally {
        setLoading(false);
      }
    });
  };

  const name = get(assignmentData, 'nurse.first_name') ? `${get(assignmentData, 'nurse.first_name')} ${get(
    assignmentData,
    'nurse.last_name',
  )}` : null;

  const title = `Rate a nurse ${name || ''}`;

  return (
    <>
      <div
        onClick={() => setOpen(true)}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {element}
      </div>
      <Modal
        title={title}
        visible={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        footer={[
          <Button key="Cancel" onClick={() => setOpen(false)}>
            Cancel
          </Button>,
          <Button
            key="Rate"
            loading={loading}
            type="primary"
            onClick={handleSubmit}
          >
            Rate a nurse
          </Button>,
        ]}
      >
        <Form onSubmit={handleSubmit}>
          <Form.Item label="Please rate the nurse">
            {getFieldDecorator('rating', {
              rules: [{ required: true, message: 'Please fill score' }],
            })(<Rate style={{ fontSize: 36 }} />)}
          </Form.Item>
          <Form.Item label="Feedback">
            {getFieldDecorator('feedback', {
              initialValue: '',
              rules: [
                { max: 255, message: 'Feedback field shouldn’t contain more than 255 symbols' },
                { required: true, message: 'Please fill feedback' },
              ],
            })(<Input.TextArea rows={4} />)}
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export const RateAssignmentModal =  Form.create({})(RateAssignmentModalComp);
