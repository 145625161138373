import React from 'react';
import s from './Status.module.scss';

const statusColors = title => {
  const color = {
    Draft: '#CCC4C3',
    Available: '#6EBF4C',
    Applied: '#6EBF4C',
    Assigned: '#6EBF4C',
    'In progress': 'linear-gradient(180deg, #F2A096 0%, #F4B4AB 100%)',
    'Pending approval': '#585295',
    'Not approved': '#F9AAA3',
    'Approved': '#565656',
    Canceled: '#585295',
    Done: '#57D9C7',
  };

  return color[title];
};

function Status({ status, ...props }) {
  return (
    <div className={`${s.tag}`} {...props} style={{ background: statusColors(status) }}>
      {status}
    </div>
  );
}

export default Status;
