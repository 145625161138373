import React from 'react';
import { getColor } from 'helpers/theme';

/* eslint-disable */
export const PatientComponent = ({ color }) => {
  const iconColor = getColor(color);
  return (
    <svg width="23" height="26" viewBox="0 0 23 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22 25V22.3333C22 20.9188 21.4469 19.5623 20.4623 18.5621C19.4777 17.5619 18.1424 17 16.75 17H6.25C4.85761 17 3.52226 17.5619 2.53769 18.5621C1.55312 19.5623 1 20.9188 1 22.3333V25"
        stroke={iconColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5 11.6667C14.3995 11.6667 16.75 9.27885 16.75 6.33333C16.75 3.38781 14.3995 1 11.5 1C8.6005 1 6.25 3.38781 6.25 6.33333C6.25 9.27885 8.6005 11.6667 11.5 11.6667Z"
        stroke={iconColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
