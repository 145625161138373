import React from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import {
  SETTINGS,
  ACCOUNT,
  FA_SETTINGS_BLOCKED_NURSES,
  PAYMENT_HISTORY,
  REPORTS,
} from 'routes/index';

import gear from 'assets/images/icons/gear.svg';
import patient from 'assets/images/icons/patient.svg';
import signout from 'assets/images/icons/signout.svg';
import docIcon from 'assets/images/icons/document.svg';
import { useStore } from 'hooks';
import { SadIcon, PaymentsHistoryIcon } from 'components';
import classes from './UserMenu.module.scss';

const UserMenu = observer(() => {
  const { auth } = useStore();

  return (
    <Menu className={classes.menu} style={{ boxShadow: '0px 4px 24px rgba(68, 153, 169, 0.1)' }}>
      <Menu.Item className={classes.menuItem}>
        <Link to={ACCOUNT}>
          <img src={patient} alt="" />
          <span>Account</span>
        </Link>
      </Menu.Item>
      {auth.isAdmin && (
        <Menu.Item className={classes.menuItem}>
          <Link to={REPORTS}>
            <img src={docIcon} alt="" />
            <span>Reports</span>
          </Link>
        </Menu.Item>
      )}
      <Menu.Item className={classes.menuItem}>
        <Link to={PAYMENT_HISTORY}>
          <PaymentsHistoryIcon />
          <span>Payment History</span>
        </Link>
      </Menu.Item>
      {!auth.isAdmin && (
        <Menu.Item className={classes.menuItem}>
          <Link to={FA_SETTINGS_BLOCKED_NURSES}>
            <SadIcon />
            <span>Blocked Nurses</span>
          </Link>
        </Menu.Item>
      )}
      <Menu.Item className={classes.menuItem}>
        <Link to={SETTINGS}>
          <img src={gear} alt="" />
          <span>Settings</span>
        </Link>
      </Menu.Item>
      <Menu.Item className={classes.menuItem} onClick={() => auth.logout()}>
        <img src={signout} alt="" />
        <span>Sign out</span>
      </Menu.Item>
    </Menu>
  );
});

export default UserMenu;
