import moment from 'moment';

export const fullDateUSFormat = 'MM-DD-yyyy';

const Time = {
  getAvailableTimeOfAssignment: () =>
    moment(new Date())
      .add(1, 'days')
      .hours(12)
      .minutes(0),
  formatDate: time => moment(time).format('MMM. DD, YYYY hh:mm A'),
  formatDateAssignmentDetails: (time, assignment) => {
    let assDur;
    if (moment.utc(moment.duration(assignment.duration * 60, "minutes").asMilliseconds()).format('mm') > 0) {
      assDur = `${moment.utc(moment.duration(assignment.duration * 60, "minutes").asMilliseconds()).format('h')}h
      ${moment.utc(moment.duration(assignment.duration * 60, "minutes").asMilliseconds()).format('mm')}m`;
    } else {
      assDur = `${moment.utc(moment.duration(assignment.duration * 60, "minutes").asMilliseconds()).format('h')}h`;
    }
    return `
    ${moment(time).format('MMM. DD, YYYY hh:mm A')} - ${moment(time)
        .add(assignment.duration * 60, 'minutes')
        .format('hh:mm A')} (${assDur})
  `;
  },
  formatDateFullMonth: time => moment(time).format('MMMM DD, YYYY hh:mm A'),
  formatOnlyDate: time => moment(time).format('MMM DD'),
  formatTime: time => moment(time).format('hh:mm A'),
  notificationItem: time => moment(time).format('MMM D [at] hh:mm A'),
  assignmentDetails: (start, end) =>
    `${moment(start).format('MMM, D [at] hh:mm A')}-${moment(end).format('hh:mm A')}`,
  assignmentStartEndByDuration: assignment => {
    const start = moment(assignment.start);
    return `${start.format('MMM DD, hh:mm A')} - ${start
      .add(assignment.duration * 60, 'minutes')
      .format('hh:mm A')}`;
  },
  assignmentStartEndTimeByDuration: assignment => {
    const start = moment(assignment.start);
    return `${start.format('hh:mm A')} - ${start
      .add(assignment.duration * 60, 'minutes')
      .format('hh:mm A')}`;
  },
  assignmentDuration: dur => {
    if (moment.utc(moment.duration(dur * 60, "minutes").asMilliseconds()).format('mm') > 0) {
      return `${moment.utc(moment.duration(dur * 60, "minutes").asMilliseconds()).format('H')}h
      ${moment.utc(moment.duration(dur * 60, "minutes").asMilliseconds()).format('mm')}m`;
    }
    return `${moment.utc(moment.duration(dur * 60, "minutes").asMilliseconds()).format('H')}h`;
  },
  normalizeDuration: durTime => Number(durTime.format('H')) + Number(durTime.format('mm') / 60),

  getDisabledHours: (originalStart, startDraftValue) => {
    const hours= [];
    if (moment(originalStart).day() === moment(startDraftValue).day()) {
      [...Array(24).keys()].forEach((_, index) => {
        if (moment(originalStart).subtract(8 + index, 'h').day() === moment(originalStart).day() &&
          moment(originalStart).subtract(8 + index, 'h').hours() < moment(originalStart).subtract(8, 'h').hours()) {
          hours.push(moment(originalStart).subtract(8 + index, 'h').hours());
        }
        if (moment(originalStart).add(8 + index, 'h').day() === moment(originalStart).day() &&
          moment(originalStart).add(8 + index, 'h').hours() > moment(originalStart).add(8, 'h').hours()) {
          hours.push(moment(originalStart).add(8 + index, 'h').hours());
        }
      });
    }
    if (moment(originalStart).day() < moment(startDraftValue).day()) {
      [...Array(24).keys()].forEach((_, index) => {
        if (moment(originalStart).add(8 + index, 'h').hours() > moment(originalStart).add(8, 'h').hours()) {
        hours.push(moment(originalStart).add(8 + index, 'h').hours());
      }
      });
    }
    if (moment(originalStart).day() > moment(startDraftValue).day()) {
      [...Array(24).keys()].forEach((_, index) => {
        if (moment(originalStart).subtract(8 + index, 'h').hours() < moment(originalStart).subtract(8, 'h').hours()) {
        hours.push(moment(originalStart).subtract(8 + index, 'h').hours());
      }
      });
    }
    return hours;
  },

};

export default Time;
