
import React from 'react';
import { getColor } from 'helpers/theme';

/* eslint-disable */
export const SearchIcon = ({ color }) => {
  const iconColor = getColor(color);
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.5 17.5C13.9183 17.5 17.5 13.9183 17.5 9.5C17.5 5.08172 13.9183 1.5 9.5 1.5C5.08172 1.5 1.5 5.08172 1.5 9.5C1.5 13.9183 5.08172 17.5 9.5 17.5Z" stroke={iconColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M19.4999 19.5L15.1499 15.15" stroke={iconColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
