export { default as icons } from './icons'
export { default as noapplies } from './noapplies.png'
export { default as back } from './back.svg'
export { default as avatar_purple} from './avatar_purple.png'
export { default as avatar_default } from './avatar_default.png'
export { default as check } from './check.svg'
export { default as bg_ripple } from './bgripple.png'
export { default as add_nurse } from './add-nurse.png'
export { default as rate_single } from './rate_single.svg'
export { default as rate_white } from './rate_white.svg'
export { default as menu } from './menu.svg'
export { default as logo } from './GapNurse-Logo-white.svg'
export { default as logo2 } from './GapNurse-Logo-Final-4-Color.svg'
export { default as orientation_empty } from './orientation_empty.png'

