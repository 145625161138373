import React from 'react';
import { useLocalStore } from 'mobx-react-lite';
import createAppStore from './createAppStore';

const StoreContext = React.createContext(null);

export const StoreProvider = ({ children }) => {
  const app = useLocalStore(createAppStore);

  return <StoreContext.Provider value={{ app }}>{children}</StoreContext.Provider>;
};

export const useGlobalStore = () => {
  const store = React.useContext(StoreContext);

  if (!store) {
    throw new Error('useStore must be used within a StoreProvider.');
  }

  return store.auth;
};
