import React from 'react';
import { useLocation } from 'react-router-dom';
import { ASSIGMENT_CREATE, ASSIGNMENTS, EMPLOYEES } from 'routes';
import { HeaderAction } from 'components';

const FacilityAdminLinks = () => {
  const location = useLocation();
  const hideNav = location.pathname === ASSIGMENT_CREATE;

  return (
    !hideNav && (
      <>
        <HeaderAction to={`${ASSIGMENT_CREATE}?step=1`} iconName="Plus">
          Add Assignment
        </HeaderAction>
        <HeaderAction to={ASSIGNMENTS} iconName="Assignments">
          Assignments
        </HeaderAction>
        <HeaderAction to={EMPLOYEES} iconName="Employees">
          Employees
        </HeaderAction>
      </>
    )
  );
};

export default FacilityAdminLinks;
