import React from 'react';
import { getColor } from 'helpers/theme';

/* eslint-disable */
export const TotalAssignment = ({ color }) => {
  const iconColor = getColor(color);
  return (
    <svg width="26" height="28" viewBox="0 0 26 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.4 2.8H16.548C15.96 1.176 14.42 0 12.6 0C10.78 0 9.24 1.176 8.652 2.8H2.8C1.26 2.8 0 4.06 0 5.6V25.2C0 26.74 1.26 28 2.8 28H22.4C23.94 28 25.2 26.74 25.2 25.2V5.6C25.2 4.06 23.94 2.8 22.4 2.8ZM12.6 2.8C13.37 2.8 14 3.43 14 4.2C14 4.97 13.37 5.6 12.6 5.6C11.83 5.6 11.2 4.97 11.2 4.2C11.2 3.43 11.83 2.8 12.6 2.8ZM14 22.4H7C6.23 22.4 5.6 21.77 5.6 21C5.6 20.23 6.23 19.6 7 19.6H14C14.77 19.6 15.4 20.23 15.4 21C15.4 21.77 14.77 22.4 14 22.4ZM18.2 16.8H7C6.23 16.8 5.6 16.17 5.6 15.4C5.6 14.63 6.23 14 7 14H18.2C18.97 14 19.6 14.63 19.6 15.4C19.6 16.17 18.97 16.8 18.2 16.8ZM18.2 11.2H7C6.23 11.2 5.6 10.57 5.6 9.8C5.6 9.03 6.23 8.4 7 8.4H18.2C18.97 8.4 19.6 9.03 19.6 9.8C19.6 10.57 18.97 11.2 18.2 11.2Z"
        fill="#C8C8C8"
      />
    </svg>
  );
};
