import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Dropdown } from 'antd';

import { Notifications } from 'modules';
import { useStore } from 'hooks';
import logo from 'assets/images/GapNurse-Logo-white.svg';
import profile from 'assets/images/icons/profile.svg';
import s from './style.module.scss';

import FacilityAdminLinks from './FacilityAdminLinks';
import UserMenu from './UserMenu';
import PlatformAdminNav from './PlatformAdminNav';

const AppHeader = observer(() => {
  const [isProfileMenuVisible, setIsProfileMenuVisible] = useState(false);
  const { auth: { isAdmin } } = useStore();

  return (
    <div className={s.header}>
      <NavLink className={s.title} to="/">
        <img style={{ minWidth: 100 }} className={s.logo} src={logo} alt="" href="/facilities" />
      </NavLink>
      <div className={s.actionItems}>{isAdmin ? <PlatformAdminNav /> : <FacilityAdminLinks />}</div>
      <div className={s.userManagment}>
        <Notifications />
        <div className={s.splitter} />
        <Dropdown
          getPopupContainer={trigger => trigger.parentNode}
          overlay={(
            <div onClick={() => setIsProfileMenuVisible(false)}>
              <UserMenu />
            </div>
          )}
          className={s.dropdown}
          trigger={['click']}
          visible={isProfileMenuVisible}
          onVisibleChange={(shown) => setIsProfileMenuVisible(shown)}
        >
          <div className={s.action}>
            <img src={profile} alt="" />
            Profile
          </div>
        </Dropdown>
      </div>
    </div>
  );
});

export default AppHeader;
