import React, { useState } from 'react';
import { Modal } from 'antd';
import { Button } from 'components';

export const ResetDecisionModal = ({ onSubmit, element }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    await onSubmit();
    setLoading(false);
  };

  return (
    <>
      <div
        onClick={() => setOpen(true)}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {element}
      </div>
      <Modal
        title={`Do you want to reset "Not approved" decision?`}
        visible={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        footer={
          [
            <Button key="Yes" loading={loading} type="danger" onClick={handleSubmit}>
              Yes
            </Button>,
            <Button key="No" onClick={() => setOpen(false)}>
              No
            </Button>,
          ]
        }
      />
    </>
  );
};
