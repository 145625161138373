import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Notification } from 'components';
import { NURSE_APPLICATIONS } from 'routes';

import api from 'api';
import { history } from 'stores/routing';
import { RejectApplicationModal } from './RejectApplicationModal';

export const ApplicationActions = observer(({ user }) => {
  const name = user.first_name ? `${user.first_name} ${user.last_name}` : null;

  const [loading, setLoading] = useState(false);

  const handleApprove = async () => {
    setLoading(true);

    try {
      await api.platformAdmin.nurseApplications.approve(user.application);
      Notification({
        message: 'Success',
        description: 'Nurse application was approved.',
      });
      history.push(NURSE_APPLICATIONS);
    } catch (err) {
      Notification({
        type: 'error',
        message: 'Error',
        description: `Can't approve nurse applications. Error message: ${err.message}.`,
      });
    }
  };

  return (
    <>
      <Button type="primary" onClick={handleApprove} loading={loading}>
        Approve
      </Button>
      <RejectApplicationModal name={name} pk={user.application} />
    </>
  );
});
