import ky from 'ky';
import { store } from 'stores';
import renewSession from './renewSession';

const apiUrl = process.env.REACT_APP_API_ENDPOINT || 'https://test.gapnurse.com/api/v1';

function RequestException({ success, status, message, data }) {
  this.success = success;
  this.status = status;
  this.message = message;
  this.data = data;
}

const handleResponse = async response => {
  if (!response || response.status === 500) {
    throw new RequestException({
      success: false,
      message: '500 HTTP Error',
      status: 500,
      response,
    });
  }

  let body;
  try {
    body = await response.json();
  } catch {
    body = null;
  }

  if (response.status >= 400 && response.status < 600) {
    const message = (body && body.detail) || (body && body.msg) || (
      (typeof body === 'object' && Object.keys(body).length)
        ? Object.keys(body).reduce((collection, key) => {
          return `${collection} ${key}: ${body[key]}.`;
        }, '')
        : ''
    );
    throw new RequestException({
      success: false,
      status: response.status,
      data: body,
      message,
      response,
    });
  }

  return {
    success: true,
    status: response.status,
    data: body,
    response,
  };
};

const request = async (method, url, params, { formData, headers, authToken } = {}) => {
  const loggedIn = localStorage.getItem('loggedIn');
  if (loggedIn && !authToken) {
    try {
      await renewSession();
    } catch (err) {
      console.log('Error while renew: ', err);
      return null;
    }
  }

  const token = localStorage.getItem('accessToken');

  const options = {
    method: method.toUpperCase(),
    prefixUrl: apiUrl,
    searchParams: method === 'get' ? params : undefined,
    json: method !== 'get' && !formData ? params : undefined,
    body: method !== 'get' && formData ? params : undefined,
    headers: token || authToken
      ? {
        ...headers,
        Authorization: `Bearer ${authToken || token}`,
      }
      : headers,
    throwHttpErrors: false,
  };

  try {
    return await ky(url.substr(1), options).then(handleResponse);
  } catch (err) {
    if (
      err.status === 401
      && !err.message.includes('The email or password')
      && !err.message.includes('Your account is not verified.')
      && !err.message.includes('Your account is inactive.')
    ) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('expires');
      localStorage.removeItem('loggedIn');
      localStorage.removeItem('token');
      store.auth.logout();

      return Promise.resolve({ data: {} });
    }
    throw err;
  }
};

export default request;
