import React from 'react';
import { getColor } from 'helpers/theme';

/* eslint-disable */
export const Card = ({ color }) => {
  const iconColor = getColor(color);
  return (
    <svg width="26" height="19" viewBox="0 0 26 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.25 1H3.125C1.95139 1 1 1.95139 1 3.125V15.875C1 17.0486 1.95139 18 3.125 18H22.25C23.4236 18 24.375 17.0486 24.375 15.875V3.125C24.375 1.95139 23.4236 1 22.25 1Z"
        stroke={iconColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 7.375H24.375"
        stroke={iconColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
