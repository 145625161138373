import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Icon } from 'antd';
import { observer } from 'mobx-react-lite';

import { ASSIGMENT_CREATE, MAIN, SETTINGS, ACCOUNT } from 'routes/index';
import { history } from 'stores/routing';
import { useStore } from 'hooks';
import s from './style.module.scss';

const Header = observer(() => {
  const { auth } = useStore();

  return (
    <header className={s.header}>
      <div className={s.headerContent}>
        <div className={s.left}>
          {auth.authenticated && (
            <div className={s.actions}>
              <Link to={MAIN}>
                <Button type="link">Assignments</Button>
              </Link>
              <Link to={ASSIGMENT_CREATE}>
                <Button type="link" className={s.action}>
                  <Icon type="plus-circle" theme="filled" style={{ fontSize: '20px' }} />
                  New
                </Button>
              </Link>
            </div>
          )}
        </div>

        <div className={s.right}>
          <div className={s.profileWrapper}>
            <Button onClick={() => history.push(SETTINGS)} type="link" className={s.action}>
              <Icon type="setting" theme="filled" style={{ fontSize: '30px' }} />
              Settings
            </Button>
            <Button type="link" className={s.action}>
              <Icon type="bell" theme="filled" style={{ fontSize: '30px' }} />
            </Button>
            <div className={s.profileButton}>
              <Button onClick={() => history.push(ACCOUNT)} type="link" className={s.action}>
                <Icon type="user" style={{ fontSize: '30px' }} />
                Profile
              </Button>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
});

export default Header;
