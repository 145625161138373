import React from 'react';
import { observer } from 'mobx-react-lite';
import { Row, Col } from 'antd';
import classNames from 'classnames';

import { ASSIGMENT } from 'routes';
import { history } from 'stores/routing';
import Time from 'helpers/Time';
import classes from './NotifMenu.module.scss';

export const Notification = observer(({ notification, lastItem, hideMenu, markAsReas }) => (
  <Row
    className={classNames({
      [classes.notifWrapper]: true,
      [classes.notifWrapperUnread]: !notification.read,
      'separated': !lastItem,
    })}
    type="flex"
    justify="space-around"
    align="middle"
    onClick={() => {
      if (notification.action === 4) {
        history.push(`${ASSIGMENT}/${notification.relation}`);
        hideMenu();
      }
      if (!notification.read) {
        markAsReas([notification.pk]);
      }
    }}
  >
    <Col xs={2}>
      {!notification.read && <div className={classes.dot} />}
    </Col>
    <Col xs={22}>
      <Row className={classes.dateWrapper}>
        <span className={classes.date}>
          {Time.notificationItem(notification.created)}
        </span>
      </Row>
      <Row>
        <span
          className={
            classNames({
              [classes.title]: true,
              [classes.titleUnread]: !notification.read
            })
          }
        >
          {notification.msg}
        </span>
      </Row>
    </Col>
  </Row>
));
