import React from 'react';

import { HeaderAction } from 'components';
import { FACILITIES_LIST, USERS, NURSE_APPLICATIONS, ASSIGNMENTS } from 'routes/index';

import assignments from 'assets/images/icons/assignment-page.svg';
import application from 'assets/images/icons/application.svg';

const PlatformAdminNv = () => (
  <>
    <HeaderAction to={FACILITIES_LIST} iconName="Home">
      Facilities
    </HeaderAction>
    <HeaderAction to={USERS} iconName="Employees">
      Users
    </HeaderAction>
    <HeaderAction
      to={NURSE_APPLICATIONS}
      iconName="Document"
      icon={<img src={application} alt="" />}
    >
      Nurse Applications
    </HeaderAction>
    <HeaderAction to={ASSIGNMENTS} iconName="Assignments" icon={<img src={assignments} alt="" />}>
      Assignments
    </HeaderAction>
  </>
);

export default PlatformAdminNv;
