import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import { NavLink } from 'components';
import classes from './style.module.scss';

export const ListItem = ({ icon, iconName, title, to, subListItems, match }) => {
  const isMatched = useRouteMatch(match || to);
  return to
    ? (
      <div className={classes.listItem}>
        <NavLink
          title={title}
          to={to}
          icon={icon}
          iconName={iconName}
          key={to}
        />
        {isMatched && subListItems && subListItems.length && (
          <ul className={classes.subList}>
            {subListItems.map(
              ({ title: subItemTitle, to: subItemTo }) => (
                <li key={subItemTo}>
                  <NavLink to={subItemTo} title={subItemTitle} />
                </li>
              )
            )}
          </ul>
        )}
      </div>
    )
    : (
      <div className={classes.listItem}>
        <div className={classes.iconContainer}>{icon}</div>
        <span>{title}</span>
      </div>
    );
};
