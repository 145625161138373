/* eslint-disable camelcase */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { SIGN_IN, FORBIDDEN } from 'routes';
import { useStore } from 'hooks';
import Layout from './layout';
import ErrorBoundary from '../Error';

const App = observer(({ fmOnly, paOnly, render, title, ...rest }) => {
  const { auth: { loggedIn, isAdmin } } = useStore();

  if (paOnly && !isAdmin) {
    return <Redirect to={FORBIDDEN} />;
  }

  if (fmOnly && isAdmin) {
    return <Redirect to={FORBIDDEN} />;
  }

  return loggedIn ? (
    <Route
      {...rest}
      render={(matchProps) => (
        <ErrorBoundary>
          <Layout title={title}>{render(matchProps)}</Layout>
        </ErrorBoundary>
      )}
    />
  ) : (
    <Redirect to={`${SIGN_IN}${window.location.pathname !== '/' ? `?redirect=${window.location.pathname}` : ''}`} />
  );
});

export default App;
