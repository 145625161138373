import React from 'react';
import { getColor } from 'helpers/theme';

/* eslint-disable */
export const AmountEarned = ({ color }) => {
  const iconColor = getColor(color);
  return (
    <svg width="29" height="30" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M26.2796 7.26373H4.31348C4.05112 7.31451 3.78078 7.30664 3.52182 7.24068C3.26286 7.17473 3.02169 7.05233 2.81558 6.88224C2.60947 6.71216 2.44352 6.4986 2.32962 6.25687C2.21572 6.01513 2.15668 5.7512 2.15674 5.48397V5.44954L25.3734 4.54515V2.38842C25.4113 2.06663 25.3755 1.74044 25.2688 1.4345C25.1621 1.12856 24.9873 0.850855 24.7576 0.622359C24.5279 0.393863 24.2493 0.22055 23.9428 0.115508C23.6363 0.010466 23.3099 -0.0235593 22.9883 0.0160016L2.37423 3.3671C1.67851 3.62393 1.07808 4.08757 0.653645 4.69571C0.22921 5.30385 0.00110853 6.02735 0 6.76895L0 26.292C0 27.013 0.286421 27.7045 0.796254 28.2143C1.30609 28.7241 1.99757 29.0106 2.71858 29.0106H26.2796C27.0006 29.0106 27.6921 28.7241 28.2019 28.2143C28.7118 27.7045 28.9982 27.013 28.9982 26.292V9.96238L29 9.88807C29 9.19109 28.7231 8.52266 28.2303 8.02982C27.7374 7.53698 27.069 7.26011 26.372 7.26011L26.276 7.26192L26.2796 7.26373ZM23.561 19.9504C22.8398 19.9502 22.1482 19.6635 21.6383 19.1533C21.1285 18.6431 20.8422 17.9513 20.8424 17.23C20.8427 16.5088 21.1294 15.8172 21.6396 15.3073C22.1498 14.7975 22.8416 14.5112 23.5628 14.5115C23.92 14.5116 24.2736 14.582 24.6035 14.7188C24.9334 14.8556 25.2331 15.056 25.4855 15.3086C25.738 15.5612 25.9382 15.8611 26.0748 16.1911C26.2113 16.5211 26.2815 16.8747 26.2814 17.2319C26.2813 17.589 26.2108 17.9426 26.0741 18.2725C25.9373 18.6024 25.7369 18.9021 25.4843 19.1546C25.2316 19.407 24.9318 19.6072 24.6018 19.7438C24.2718 19.8803 23.9182 19.9506 23.561 19.9504Z"
        fill="#C8C8C8"
      />
    </svg>
  );
};
