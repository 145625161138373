import React from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';

import other from 'assets/images/other.svg';
import { Header } from 'components';
import Item from 'components/user/UserGeneralInfo/Item/Item';
import { FileLink } from '../FileLink';

const LinksList = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
`;

const labels = {
  photo_id: 'Photo ID',
  nurse_license: 'Nurse license',
  consent_form: 'Consent form',
  e_handbook: 'E-handbook',
  hipaa: 'HIPAA',
  vaccination_records: 'Vaccination forms',
};

export const ApplicationsInfo = ({ user }) => (
  <>
    <Header type="h2" gutterBottom="lg" style={{ paddingTop: 44 }}>
      <img src={other} alt="" style={{ marginRight: 12 }} />
      <span>
        Documents
      </span>
    </Header>
    <Row gutter={[0, 30]} style={{ marginTop: 30 }}>
      {Object.keys(labels)
        .map(key => user.documents[key] && user.documents[key] ? (
          <Col span={12} key={key}>
            <Item custom field={labels[key] ? labels[key] : key}>
              {user.documents[key] && user.documents[key].length ? (
                <LinksList>
                  {user.documents[key].map((document) => (
                    <FileLink key={document.file} path={document.file} name={document.filename} />
                  ))}
                </LinksList>
              ) : '-'}
            </Item>
          </Col>
        ) : null
      )}
    </Row>
  </>
);
