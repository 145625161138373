import styled from 'styled-components';

export const Badge = styled.span`
  padding: 3px 6px;
  display: inline-block;
  height: 17px;
  font-size: 10px;
  line-height: 12px;
  color: #ffffff;
  border-radius: 4px;
  margin-bottom: 5px;
  background: ${(props) => props.color || '#529591'};
  margin-left: 5px;
`;
