import React from 'react';
import 'antd/dist/antd.css';
import { Router } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import * as Sentry from '@sentry/browser';

import { history } from 'stores/routing';
import { useStore } from 'hooks';
import { AppLoader } from 'components';
import Routes from 'routes/setup';
import s from './App.module.scss';
import './styles/main.scss';

// import { register } from './serviceWorker';
// register();

if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'test') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_KEY,
    environment: process.env.NODE_ENV,
  });
}

function App() {
  const { auth } = useStore();

  return (
    <div className={s.app}>
      {(auth.loading) && <AppLoader />}
      <Router history={history}>
        <Routes />
      </Router>
    </div>
  );
}

export default observer(App);
