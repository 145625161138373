import React from 'react';
import { getColor } from 'helpers/theme';

/* eslint-disable */
export const FileIcon = ({ color }) => {
  const iconColor = getColor(color);
  return (
    <svg width="11" height="22" viewBox="0 0 11 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.5 5V16.5C9.5 17.5609 9.07857 18.5783 8.32843 19.3284C7.57828 20.0786 6.56087 20.5 5.5 20.5C4.43913 20.5 3.42172 20.0786 2.67157 19.3284C1.92143 18.5783 1.5 17.5609 1.5 16.5V4C1.5 3.33696 1.76339 2.70107 2.23223 2.23223C2.70107 1.76339 3.33696 1.5 4 1.5C4.66304 1.5 5.29893 1.76339 5.76777 2.23223C6.23661 2.70107 6.5 3.33696 6.5 4V14.5C6.5 14.7652 6.39464 15.0196 6.20711 15.2071C6.01957 15.3946 5.76522 15.5 5.5 15.5C5.23478 15.5 4.98043 15.3946 4.79289 15.2071C4.60536 15.0196 4.5 14.7652 4.5 14.5V5H3V14.5C3 15.163 3.26339 15.7989 3.73223 16.2678C4.20107 16.7366 4.83696 17 5.5 17C6.16304 17 6.79893 16.7366 7.26777 16.2678C7.73661 15.7989 8 15.163 8 14.5V4C8 2.93913 7.57857 1.92172 6.82843 1.17157C6.07828 0.421427 5.06087 0 4 0C2.93913 0 1.92172 0.421427 1.17157 1.17157C0.421427 1.92172 0 2.93913 0 4V16.5C0 17.9587 0.579462 19.3576 1.61091 20.3891C2.64236 21.4205 4.04131 22 5.5 22C6.95869 22 8.35764 21.4205 9.38909 20.3891C10.4205 19.3576 11 17.9587 11 16.5V5H9.5Z" fill={iconColor} />
    </svg>
  );
};
