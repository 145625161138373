import React, { useCallback, useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Select, Spin } from 'antd';

import { useStore } from 'hooks';

export const NurseSelect = observer(({ value, onChange }) => {
  const { types } = useStore();
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    types.getFilterNursesList(value, 10);
  }, [types]);

  const fetchNurses = useCallback(
    async val => {
      setFetching(true);
      await types.getFilterNursesList(val, val.length > 1 ? 100 : 10);
      setFetching(false);
    }, [types]);

  return (
    <Select
      getPopupContainer={trigger => trigger.parentNode}
      showSearch
      allowClear
      placeholder="Nurse"
      notFoundContent={fetching ? <Spin size="small" /> : null}
      style={{ width: 120 }}
      filterOption={false}
      onSearch={fetchNurses}
      onChange={onChange}
      dropdownMatchSelectWidth={false}
      value={value || undefined}
    >
      {types.filtersNursesList &&
        types.filtersNursesList.map(({ pk, first_name, last_name }) => (
          <Select.Option key={pk} value={pk}>
            {first_name}
            {' '}
            {last_name}
          </Select.Option>
        ))}
    </Select>
  );
});
