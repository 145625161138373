import React from 'react';
import { observer } from 'mobx-react-lite';
import { Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { MAIN } from 'routes';
import { useStore } from 'hooks';
import s from './style.module.scss';

const AppLayout = observer(({ title, children, doNotRedirect }) => {
  const { auth } = useStore();

  if (auth.authenticated && !doNotRedirect) {
    return <Redirect to={MAIN} />;
  }

  return (
    <div className={s.content}>
      {title && (
        <Helmet>
          <title>{title}</title>
        </Helmet>
      )}
      <div className={s.formWrapper}>
        {children}
      </div>
    </div>
  );
});

export default AppLayout;
