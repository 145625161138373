import { observable, action, computed } from 'mobx';
import api from 'api';
import { Notification } from 'components';

class ReportsStore {
  @observable error = null;
  @observable loading = false;
  @observable report_url = false;

  @action
  setLoading = (loading = true) => {
    this.loading = loading;
  };

  generateReport = async (values, onSuccess) => {
    this.setLoading();
    try {
      const response = await api.platformAdmin.reports.create(values);
      onSuccess();
      return response.data?.file;
    } catch (err) {
      if (!err.data || (err.data && !err.data.address)) {
        Notification({
          type: 'error',
          message: 'Error occurred',
          description: `Error: ${err.message}`,
        });
      }
      throw err;
    } finally {
      this.setLoading(false);
    }
  };
}

export default new ReportsStore();
