import React from 'react';
import styled from 'styled-components';

import { getMediaPath } from 'helpers/getMediaPath';
import { ClipIcon } from 'components';

const Link = styled.a`
  color: #6FC04D;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.01em;
  display: flex;
  align-items: center;
  margin: 10px 0;

  svg {
    margin-right: 10px;
    width: 18px;
  }

  &:hover {
    color: #6FC04D;
  }
`;

const Name = styled.span`
  display: inline-block;
  max-width: calc(100% - 28px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const FileLink = ({ path, name }) => (
  <Link href={getMediaPath(path)} target="_blank">
    <ClipIcon color="#6FC04D" />
    <Name title={name}>{name}</Name>
  </Link>
);
