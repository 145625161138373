import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Rate } from 'antd';
import { get } from 'lodash';

import api from 'api';
import { Button, Notification } from 'components';

export const PayAssignmentModalComp = ({ form, assigmentId, element, onSuccess }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [assignmentData, setAssignmentData] = useState({});
  const { getFieldDecorator } = form;

  useEffect(() => {
    if (open) {
      const fetchData = async () => {
        try {
          const res = await api.facility.assigment.get(assigmentId);
          setAssignmentData(res.data);
        } catch (err) {
          Notification({
            type: 'error',
            message: 'Error',
            description: `Error while loading assignment info. Error message: ${err.message}.`,
          });
        }
      };
      fetchData();
    }
  }, [open]);

  const handleSubmit = () => {
    form.validateFields(async (validationErr, { feedback, rating }) => {
      if (validationErr) {
        return;
      }

      setLoading(true);

      try {
        const result = await api.facility.assigment.approvePayment(assigmentId, {
          feedback,
          rating,
        });
        setOpen(false);
        Notification({
          message: 'Success',
          description: 'Assignment was approved.',
        });
        if (result.success && onSuccess) {
          onSuccess();
        }
      } catch (err) {
        Notification({
          type: 'error',
          message: 'Error',
          description: `Can't approve payment. Error message: ${err.message}.`,
        });
      } finally {
        setLoading(false);
      }
    });
  };

  const footer = (
    <div className="ant-modal-footer-flex">
      <div className="left-col-text">
        {`Amount to pay: $${assignmentData.amount || '0.00'}`}
      </div>
      <div className="buttons">
        <Button onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <Button
          loading={loading}
          type="primary"
          onClick={handleSubmit}
          style={{ padding: '0 12px' }}
        >
          Confirm payment
        </Button>
      </div>
    </div>
  );

  const name = get(assignmentData, 'nurse.first_name')
    && `${get(assignmentData, 'nurse.first_name')} ${get(assignmentData, 'nurse.last_name')}`;

  const title = `Confirm payment ${name ? `to ${name}` : ''}`;

  return (
    <>
      <div
        onClick={() => setOpen(true)}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {element}
      </div>
      <Modal
        title={title}
        visible={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        footer={footer}
      >
        <Form>
          <Form.Item label="Please rate the nurse">
            {getFieldDecorator('rating', {
              rules: [{ required: true, message: 'Please fill score' }],
            })(<Rate style={{ fontSize: 36 }} />)}
          </Form.Item>
          <Form.Item label="Feedback">
            {getFieldDecorator('feedback', {
              initialValue: '',
              rules: [
                { required: true, message: 'Please fill feedback' },
                { max: 255, message: 'Feedback field shouldn’t contain more than 255 symbols' },
              ],
            })(<Input.TextArea rows={4} />)}
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export const PayAssignmentModal =  Form.create({})(PayAssignmentModalComp);
