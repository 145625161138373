export const ERROR = '/404';
export const FORBIDDEN = '/forbidden';

// auth
export const SIGN_IN = '/sign-in';
export const LOG_OUT = '/log-out';
export const FORGET_PASSWORD = '/forgot-password';
export const RESET_PASSWORD = '/reset-password';
export const ACTIVATE_ACCOUNT = '/confirm';

// routes need auth
export const MAIN = '/';
export const SETTINGS = '/settings';
export const ACCOUNT = '/account';

export const ASSIGMENT_CREATE = '/assignment/create';
export const ASSIGMENT_EDIT = '/assignment/:id/edit';
export const ASSIGMENT = '/assignment';
export const ASSIGNMENTS = '/assignments';
export const ASSIGNMENTS_LIST_VIEW = '/assignments/list';
export const ASSIGNMENTS_CALENDAR_VIEW = '/assignments/calendar';

export const NURSE = '/assignment/:assignmentId/nurse/:nurseId';
export const NURSE_GENERAL = `${NURSE}/general`;
export const NURSE_FEEDBACKS = `${NURSE}/feedbacks`;

export const FA_SETTINGS = '/settings/facility';
export const FA_SETTINGS_PAYMENTS = `${FA_SETTINGS}/payments`;
export const FA_SETTINGS_GENERAL = `${FA_SETTINGS}/general`;
export const FA_SETTINGS_ORIENTATION_DOCUMENTS = `${FA_SETTINGS}/orientation-documents`;
export const FA_SETTINGS_ORIENTATION_DOCUMENTS_ADD = `${FA_SETTINGS_ORIENTATION_DOCUMENTS}/add`;
export const FA_SETTINGS_ORIENTATION_DOCUMENTS_EDIT = `${FA_SETTINGS_ORIENTATION_DOCUMENTS}/edit/:id`;
export const FA_SETTINGS_ORIENTATION_DOCUMENTS_LIST = `${FA_SETTINGS_ORIENTATION_DOCUMENTS}/list`;
export const FA_SETTINGS_BLOCKED_NURSES = '/blocked-nurses';

export const EMPLOYEES = '/employees';
export const EMPLOYEE = `${EMPLOYEES}/:employeeId`;
export const EMPLOYEE_GENERAL = `${EMPLOYEE}/general`;
export const EMPLOYEE_FEEDBACK = `${EMPLOYEE}/feedback`;

// routes for platform administrators
export const FACILITIES_LIST = '/facilities';
export const USERS = '/users';
export const USER = '/users/:id';
export const USER_GENERAL = `${USER}/general`;
export const USER_FEEDBACK = `${USER}/feedback`;
export const USER_ASSIGNMENTS_HISTORY = `${USER}/assignments-history`;

export const NURSE_APPLICATIONS = '/nurse-applications';
export const NURSE_APPLICATIONS_LIST = `${NURSE_APPLICATIONS}/list`;
export const NURSE_APPLICATIONS_HISTORY = `${NURSE_APPLICATIONS}/history`;

export const PA_SETTINGS = '/settings/platform';

export const PA_SETTINGS_RATES = '/settings/platform/rates';
export const PA_SETTINGS_RATES_STATE = `${PA_SETTINGS_RATES}/:state`;

export const PA_SETTINGS_TRAININGS = '/settings/platform/trainings';
export const PA_SETTINGS_TRAININGS_LIST = `${PA_SETTINGS_TRAININGS}/list`;
export const PA_SETTINGS_TRAININGS_ADD = `${PA_SETTINGS_TRAININGS}/add-training`;
export const PA_SETTINGS_TRAININGS_EDIT = `${PA_SETTINGS_TRAININGS}/edit-training/:id`;

export const PAYMENT_HISTORY = '/payment-history';

export const REPORTS = '/reports';
export const REPORTS_TIMECARD = '/reports/time-card';
