import React from 'react';
import { getColor } from 'helpers/theme';

/* eslint-disable */
export const Document = ({ color }) => {
  const iconColor = getColor(color);
  return (
    <svg width="19" height="23" viewBox="0 0 19 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.45 1H3.1C2.54305 1 2.0089 1.22125 1.61508 1.61508C1.22125 2.0089 1 2.54305 1 3.1V19.9C1 20.457 1.22125 20.9911 1.61508 21.3849C2.0089 21.7787 2.54305 22 3.1 22H15.7C16.257 22 16.7911 21.7787 17.1849 21.3849C17.5787 20.9911 17.8 20.457 17.8 19.9V8.35L10.45 1Z"
        stroke={iconColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4502 1V8.35H17.8002"
        stroke={iconColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
