export default function createAppStore() {
  return {
    name: 'GapNurse',
    frontend: 0.5,
    setFrontend(value) {
      this.frontend = value
    },
    get developerLevel() {
      return this.frontend === 0.5 ? 'Redux Chmo' : 'Mobx Batya'
    }
  }
}
