import React from 'react';
import logo from 'assets/images/GapNurse-Logo-Final-4-Color.svg';
import { BarLoader } from 'react-spinners';
import s from './AppLoader.module.scss';

function AppLoader() {
  return (
    <div className={s.loader}>
      <img className={s.logo} src={logo} alt="" />
      <BarLoader color="#40205d" loading />
    </div>
  );
}

export default AppLoader;
