import React from 'react';
import { Row, Col } from 'antd';

import { Header } from 'components';
import certifications from 'assets/images/icons/certifications.svg';
import Item  from 'components/user/UserGeneralInfo/Item/Item';
import { FileLink } from '../FileLink';

export const CertificationsInfo = ({ user }) => (
  <div className="description">
    <Header type="h2" gutterBottom="lg" style={{ paddingTop: 44 }}>
      <img src={certifications} alt="" style={{paddingRight: 12}} />
      <span>Experience / Certs</span>
    </Header>

    <Row>
      <Col span={12}>
        <Item field="certifications_list">
          {
            user
            && user.profile
            && user.profile.certifications
            && user.profile.certifications.length
            && user.profile.certifications.map((certificate) => <p style={{ marginBottom: 3 }}>{certificate}</p>)
            || '-'
          }
        </Item>
      </Col>
      <Col span={12}>
        <Item field="files">
          {
            user
            && user.profile
            && user.profile.certification_files
            && user.profile.certification_files.length
            && user.profile.certification_files.map((file) => (
              <FileLink path={file.file} name={file.filename} />
            ))
            || '-'
          }
        </Item>
      </Col>
    </Row>
  </div>
);
