import React from 'react';
import { getColor } from 'helpers/theme';

/* eslint-disable */
export const Add = ({ color }) => {
  const iconColor = getColor(color);
  return (
    <svg width="15" height="22" viewBox="0 0 15 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.5 1.5V23.5"
        stroke={iconColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 5.5H5C4.07174 5.5 3.1815 5.86875 2.52513 6.52513C1.86875 7.1815 1.5 8.07174 1.5 9C1.5 9.92826 1.86875 10.8185 2.52513 11.4749C3.1815 12.1313 4.07174 12.5 5 12.5H10C10.9283 12.5 11.8185 12.8687 12.4749 13.5251C13.1313 14.1815 13.5 15.0717 13.5 16C13.5 16.9283 13.1313 17.8185 12.4749 18.4749C11.8185 19.1313 10.9283 19.5 10 19.5H1.5"
        stroke={iconColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
