import React from 'react';
import { observer } from 'mobx-react-lite';
import Container from './Item.styled';

const labels = {
  facility: 'Facility name',
  phone: 'Mobile phone',
  created: 'Registration date',
  zip_code: 'Zip code',
  last_activity: 'Last activity',
  patient_name: 'Patient name',
  address: 'Address',
  time_from: 'Time',
  time_to: 'Duration',
  contact_person: 'Case manager',
  patient_history: 'History and physical',
  special_needs: 'Special needs',
  medications_and_treatments: 'Medications and treatments',
  supplies_needed: 'Needed supplies',
  role: 'Role',
  skills: 'Skills',
  certifications: 'Experience / Certs',
  travel_reimbursement: 'Travel/Bonus',
  private_details: 'Nurse Instructions',
  public_notes: 'Public Description',
  rate: 'Rate',
  documents_to_review: 'Orientation Documents to review',
  email: 'Email',

  consent_form: 'Consent form',
  e_handbook: 'E-handbook',
  hipaa: 'HIPAA',
  id_photo: 'Photo ID',
  license_photo: 'Nurse license',
  vaccination_records: 'Vaccination records',
  covid19: 'Covid-19 Vaccination',
  languages: 'Languages',
  education: 'Education',
  certifications_list: 'List of certificates',
  files: 'Files',
  facility_name: 'Facility name',
  facility_address: 'Facility address',
  facility_platform_fee: 'Platform fee override',
};

const InfoItem = ({ field, invert, children, custom }) => (
  <Container invert={invert} style={{ paddingRight: 20 }}>
    <div className="item">
      <div className="caption">{!custom ? labels[field] : field}</div>
      <div className="description">{children}</div>
    </div>
  </Container>
);

export default observer(InfoItem);
