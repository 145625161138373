import React from 'react';
import { getColor } from 'helpers/theme';

/* eslint-disable */
export const CompleteAssignments = ({ color }) => {
  const iconColor = getColor(color);
  return (
    <svg width="26" height="29" viewBox="0 0 26 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.0885 2.91146C23.901 2.91146 24.5894 3.19358 25.1536 3.75781C25.7179 4.32205 26 5.01042 26 5.82292V26C26 26.8125 25.7179 27.5009 25.1536 28.0651C24.5894 28.6293 23.901 28.9115 23.0885 28.9115H2.91146C2.09896 28.9115 1.41059 28.6293 0.846354 28.0651C0.282118 27.5009 0 26.8125 0 26V5.82292C0 5.01042 0.282118 4.32205 0.846354 3.75781C1.41059 3.19358 2.09896 2.91146 2.91146 2.91146H8.9375C9.25347 2.05382 9.78385 1.35417 10.5286 0.8125C11.2734 0.270833 12.0972 0 13 0C13.9028 0 14.7266 0.270833 15.4714 0.8125C16.2161 1.35417 16.7465 2.05382 17.0625 2.91146H23.0885ZM13 2.91146C12.5938 2.91146 12.2552 3.05816 11.9844 3.35156C11.7135 3.64496 11.5781 3.98351 11.5781 4.36719C11.5781 4.75087 11.7135 5.08941 11.9844 5.38281C12.2552 5.67621 12.5938 5.82292 13 5.82292C13.4063 5.82292 13.7448 5.67621 14.0156 5.38281C14.2865 5.08941 14.4219 4.75087 14.4219 4.36719C14.4219 3.98351 14.2865 3.64496 14.0156 3.35156C13.7448 3.05816 13.4063 2.91146 13 2.91146ZM10.0885 23.1562L21.6667 11.5781L19.6354 9.54687L10.0885 19.026L6.36458 15.3021L4.33333 17.3333L10.0885 23.1562Z"
        fill="#C8C8C8"
      />
    </svg>
  );
};
