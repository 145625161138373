import React from 'react';
// import { getColor } from 'helpers/theme';

/* eslint-disable */
export const RatingIcon = ({ color, ...rest }) => (
  <div>
    <svg width="44" height="42" viewBox="0 0 44 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.5493 0.938132C21.7309 0.560153 22.2691 0.560153 22.4507 0.938131L28.5346 13.6021C28.6075 13.7538 28.7518 13.8587 28.9186 13.8811L42.8428 15.7539C43.2584 15.8098 43.4247 16.3216 43.1214 16.6111L32.9572 26.3107C32.8355 26.4269 32.7803 26.5965 32.8106 26.7621L35.3323 40.5835C35.4075 40.996 34.9721 41.3124 34.603 41.1133L22.2374 34.444C22.0892 34.3641 21.9108 34.3641 21.7626 34.444L9.39697 41.1133C9.0279 41.3124 8.59248 40.996 8.66774 40.5835L11.1894 26.7621C11.2197 26.5965 11.1645 26.4269 11.0428 26.3107L0.878646 16.6111C0.57528 16.3216 0.741595 15.8098 1.15719 15.7539L15.0814 13.8811C15.2482 13.8587 15.3925 13.7538 15.4654 13.6021L21.5493 0.938132Z" fill="url(#paint0_linear)" />
      <defs>
        <linearGradient id="paint0_linear" x1="22" y1="0" x2="22" y2="46" gradientUnits="userSpaceOnUse">
          <stop stopColor="currentColor" />
          <stop offset="1" stopColor="currentColor" />
        </linearGradient>
      </defs>
    </svg>
  </div>
);
