import React from 'react';
import { getColor } from 'helpers/theme';

/* eslint-disable */
export const Quote = ({ color }) => {
  const iconColor = getColor(color);
  return (
    <svg width="19" height="15" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.27708 11.525C8.27708 10.6 8.02708 9.8 7.51458 9.15C6.73958 8.175 5.22708 8.05 4.35208 8.25C3.98958 6.1875 5.73958 3.5625 8.00208 2.4375L6.26458 0C2.85208 1.625 -0.510422 5.35 0.0645777 10.0625C0.427078 13.0375 2.15208 15 4.58958 15C5.65208 15 6.53958 14.6875 7.23958 14.0625C7.93958 13.4375 8.27708 12.5875 8.27708 11.525ZM18.3396 11.525C18.3396 10.6 18.0896 9.8 17.5771 9.15C16.7896 8.175 15.2896 8.05 14.4146 8.25C14.0521 6.1875 15.8021 3.5625 18.0646 2.4375L16.3271 0C12.9146 1.625 9.56458 5.35 10.1396 10.0625C10.5021 13.0375 12.2146 15 14.6521 15C15.7146 15 16.6021 14.6875 17.3021 14.0625C18.0021 13.4375 18.3396 12.5875 18.3396 11.525Z" fill="#D1D1D1"/>
    </svg>
  );
};
