import React from 'react';
import { Button } from 'antd';
import { Link, useRouteMatch } from 'react-router-dom';
import classnames from 'classnames';

import classes from './styles.module.scss';

export const ViewTypeSwitch = ({ listRoute, calendarRoute, onSwitch }) => {
  const isListActive = useRouteMatch({
    path: listRoute,
    strinct: true,
  });

  const isCalendarActive = useRouteMatch({
    path: calendarRoute,
    strinct: true,
  });

  const handleClick = () => {
    if (onSwitch) {
      onSwitch();
    }
  };

  return (
    <>
      <Link to={listRoute} onClick={() => !isListActive && handleClick()}>
        <Button
          size="large"
          className={classnames(classes.button, { 'active': isListActive })}
          shape="circle"
          icon="unordered-list"
        />
      </Link>
      <Link to={calendarRoute} onClick={() => !isCalendarActive && handleClick()}>
        <Button
          size="large"
          className={classnames(classes.button, { 'active': isCalendarActive })}
          shape="circle"
          icon="calendar"
        />
      </Link>
    </>
  );
};
