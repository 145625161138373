import { observable, action } from 'mobx';
import api from 'api';
import { Notification } from 'components';

class UserStore {
  @observable error = null
  @observable successMessage = null
  @observable loading = false
  @observable profile = null

  @action
  setLoading = (loading = true) => {
    this.loading = loading;
  }

  @action
  setProfile = (profile) => {
    this.profile = profile;
  }

  @action
  setHasEmployees = (value) => {
    this.profile.has_employee = value || true;
  }

  getProfile = async (isAdmin) => {
    this.setLoading();
    const result = isAdmin
      ? await api.platformAdmin.profile.get()
      : await api.facility.profile.get();
    if (result.success) {
      this.setProfile(result.data);
    }

    this.setLoading(false);
  }

  updateProfile = async (values, isAdmin) => {
    this.setLoading(true);
    try {
      const response = isAdmin
        ? await api.platformAdmin.profile.update(values)
        : await api.facility.profile.put(values);

      if (response.success) {
        this.setProfile(response.data);

        Notification({
          message: `Success`,
          description: 'Profile updated',
        });
      } else {
        throw new Error(Object.values(response.data).join(', '));
      }
    } catch (err) {
      Notification({
        type: 'error',
        message: 'Error occurred',
        description: `Error while updating account. Error message: ${err.message}`,
      });
    } finally {
      this.setLoading(false);
    }
  }
}

export default new UserStore();
