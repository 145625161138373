import React from 'react';
import { getColor } from 'helpers/theme';

/* eslint-disable */
export const ClipIcon = ({ color }) => {
  const iconColor = getColor(color);
  return (
    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.3805 6.38788L11.1118 5.12036L4.7679 11.463C4.51805 11.7129 4.31988 12.0096 4.1847 12.3361C4.04952 12.6626 3.97997 13.0126 3.98003 13.3659C3.98008 13.7193 4.04975 14.0693 4.18504 14.3957C4.32033 14.7222 4.5186 15.0188 4.76852 15.2687C5.01845 15.5185 5.31514 15.7167 5.64165 15.8519C5.96816 15.987 6.31811 16.0566 6.6715 16.0565C7.02489 16.0565 7.37481 15.9868 7.70128 15.8515C8.02775 15.7162 8.32437 15.518 8.57422 15.268L16.1869 7.65665C17.0279 6.8154 17.5004 5.67449 17.5003 4.48491C17.5001 3.29532 17.0275 2.1545 16.1862 1.31342C15.345 0.472332 14.2041 -0.000117191 13.0145 2.18046e-08C11.8249 0.000117234 10.6841 0.472792 9.84299 1.31404L1.85035 9.30543L1.83285 9.32168C-0.610948 11.7655 -0.610948 15.7255 1.83285 18.1681C4.27664 20.6106 8.23671 20.6106 10.6805 18.1681L10.6968 18.1506L10.698 18.1518L16.1544 12.6967L14.8856 11.4292L9.42923 16.8831L9.41298 16.8993C7.67295 18.6394 4.8429 18.6394 3.10287 16.8993C2.68781 16.4834 2.35894 15.9896 2.13512 15.4462C1.91129 14.9029 1.79692 14.3207 1.79854 13.7331C1.80017 13.1455 1.91777 12.564 2.14459 12.0219C2.37142 11.4798 2.70302 10.9879 3.12037 10.5742L3.11912 10.573L11.113 2.58156C12.1618 1.53154 13.8693 1.53154 14.9193 2.58156C15.9693 3.63158 15.9681 5.33786 14.9193 6.38663L7.30669 13.998C7.13633 14.1547 6.91201 14.2395 6.68058 14.2348C6.44915 14.23 6.22852 14.136 6.06476 13.9724C5.901 13.8088 5.80678 13.5883 5.80178 13.3568C5.79678 13.1254 5.88138 12.901 6.03792 12.7305L12.3818 6.38663L12.3805 6.38788Z" fill={iconColor} />
    </svg>
  );
};
