import React from 'react';
import styled from 'styled-components';
import noNotifImg from './no-notifications.svg';

const Container = styled.div`
  display: flex;
  height: 320px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Text = styled.p`
  margin-top: 25px;
  font-size: 13px;
  line-height: 15px;
  color: #8A949A;
`;

export const Empty = () => (
  <Container>
    <img src={noNotifImg} alt="" />
    <Text>Feel free. Everything is done</Text>
  </Container>
);
