import React from 'react';
import { Table } from 'antd';

import Time from 'helpers/Time';
import { Header } from 'components';
import { FileLink } from '../FileLink';

const columns = [
  {
    title: 'Name',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Date',
    key: 'date',
    dataIndex: 'date',
    render: (val) => {
      return val !== '—' ? Time.formatDate(val) : '—';
    },
  },
  {
    title: 'Status',
    dataIndex: 'completed',
    key: 'completed',
    render: (val) => val ? 'Completed' : 'Uncompleted',
  },
  {
    title: 'Certificates',
    key: 'certificates',
    dataIndex: 'certificates',
    render: (files) => files && files.length
      ? files.map(({ file, filename }) => <FileLink path={file} name={filename} />)
      : '-',
  }
];

export const TrainingsInfo = ({ user }) => (
  <div className="description">
    <Header type="h2" gutterBottom="lg" style={{ paddingTop: 50 }}>
      Trainings
    </Header>

    {user.trainings && user.trainings.length ? (
      <Table
        bordered
        dataSource={user.trainings}
        columns={columns}
        rowKey={row => row.pk}
        pagination={false}
      />
    ) : '-'}
  </div>
);
